let Modal = {

    Init : () => {
        Modal.DynamicModal();
        Modal.Actions();
    },

    Actions : () => {

        $d.off('click', '#flash-notice-modal').on('click', '#flash-notice-modal', function(e){
            e.preventDefault();
            $('#global_loader').removeClass('global-loader-hidden')
            setTimeout(function() { window.location.reload(); }, 500);
            return;
        });

        $d.off('click', '.modal-btn-cancel').on('click', '.modal-btn-cancel', function(){
            $('.modal').fadeOut(300);
        });

        $d.off('click', '.dynamic-modal-close-btn').on('click', '.dynamic-modal-close-btn', function(){

            let $parent = $(this).parent().parent();
            let modalId = $parent.attr('modal-id');

            let clickGet = Modal.ModalClickGet(modalId);

            if (clickGet) {
                Modal.ModalClickSet(modalId, (clickGet+1));
            } else {
                Modal.ModalClickSet(modalId, 1);
            }
            $parent.fadeOut(100);
            return;
        });
    },

    ModalClickGet : (modalId) => {
        if (window.localStorage) {
            if (window.localStorage.getItem('modal_click_'+modalId)) {
                return parseInt(window.localStorage.getItem('modal_click_'+modalId));
            } else {
                return;
            }
        }
    },

    ModalClickSet : (modalId, value) => {
        if (window.localStorage) {
            window.localStorage.setItem('modal_click_'+modalId, value);
        }
    },

    DynamicModal : () => {
        if ($('.dynamic-modal').length) {
            

            function showModal(startDelay, endDelay, modalId) {
                setTimeout(function(){
                    $('.dynamic-modal[modal-id="'+modalId+'"]').fadeIn(100);
                    setTimeout(function(){
                        $('.dynamic-modal[modal-id="'+modalId+'"]').fadeOut(100);
                    },endDelay * 1000);
                },startDelay * 1000);
            }

            $('.dynamic-modal').each( function() {

                var modalId     = $(this).attr('modal-id'), 
                    startDelay  = $(this).attr('show-delay'), 
                    endDelay    = $(this).attr('hide-delay'), 
                    maxClick    = parseInt($(this).attr('max-click')), 
                    viewModal   = true,
                    clickGet    = Modal.ModalClickGet(modalId);

                if (maxClick > 0) {
                    if (clickGet) {
                        if (clickGet >= maxClick) {
                            viewModal = false;
                        }
                    }
                }
                if (viewModal) showModal(startDelay, endDelay, modalId);
            });
        }
    }
}
module.exports = Modal;