let Solutions = {

    init : () => {
        Solutions.Actions();
        Solutions.Parallax();
    },

    Actions : () => {

        $d.off('click', '.mac-point-ball').on('click', '.mac-point-ball', function(e){

            if ($(this).parent().children('.mac-point-content').is(':visible')) {
                $(this).parent().children('.mac-point-content').fadeOut(300);
                return;
            }

            $('.mac-point-content').hide();
            $(this).parent().children('.mac-point-content').fadeIn(300);
            return;
        });
    },

    Parallax : () => {

        jQuery = $;

        require('jquery-parallax.js');
        $('.parallax-window').parallax();
    }
}
module.exports = Solutions;