const now = Date.now();
document.querySelectorAll('.modal_validatefile').forEach(function(modal) {
    const recurrence= parseInt(modal.querySelector('input[name=recurrence]').value);

    modal.querySelector('input[name=valid_at]').addEventListener('change', setExpireAt);
    if (null != modal.querySelector('input[name=fiscal_year_end_at]')) {
        modal.querySelector('input[name=fiscal_year_end_at]').addEventListener('change', setExpireAt);
    }

    var submitButtons = modal.querySelectorAll('button[type=submit]');
    submitButtons.forEach((elem) => {
        elem.addEventListener('click', submitValidatefile);
    });

    function setExpireAt(event)
    {
        var validAtString = event.target.value;
        var validAt = stringToDateObject(validAtString);
        if (validAt instanceof Date) {
            let expireAt = stringToDateObject(validAtString);
            expireAt = expireAt.setMonth(expireAt.getMonth() + recurrence);
            expireAt = new Intl.DateTimeFormat('fr-FR').format(expireAt);
            modal.querySelector('input[name=expire_at]').value = expireAt;
        }
    }

    function submitValidatefile(event)
    {
        if ('ok' == event.target.value) {
            var errorText = null;
            var inputs = {'valid_at': 'date de début de validité'};
            if (0 < recurrence) {
                inputs['expire_at'] = 'date de fin de validité';
            }
            var previousDate = null;
            Object.entries(inputs).forEach(([field, label]) => {
                let dateString = modal.querySelector('input[name=' + field + ']').value;
                var dateObject = stringToDateObject(dateString);

                if (dateObject instanceof Date) {
                    switch (field) {
                        case 'valid_at' :
                            if (dateObject > now) {
                                errorText = 'La ' + label + ' ne peut pas être dans le futur';
                            }
                            break;
                        case 'expire_at' :
                            if (dateObject < now) {
                                errorText = 'La ' + label + ' ne peut pas être dans le passé';
                            }
                            break;
                    }
                    if (null !== previousDate && previousDate > dateObject) {
                        errorText = 'La ' + label + ' ne peut pas être antérieure à ' + new Intl.DateTimeFormat('fr-FR').format(previousDate);
                    }
                    previousDate = dateObject;
                } else {
                    errorText = 'La ' + label + ' ne respecte pas le format requis';
                }
            });
            if (null !== errorText) {
                event.preventDefault();
                alert(errorText);
            }
        }
    }
});

function stringToDateObject(dateString)
{
    const regex = /^([0-9]{2})\/([0-9]{2})\/([0-9]{2,4})$/;

    var dateObject = false;
    dateString = dateString.trim();
    if (dateString.match(regex)) {
        let [dateStringExec, day, month, year] = regex.exec(dateString);
        let monthIndex = month - 1;
        dateObject = new Date(year, monthIndex, day);
    }

    return dateObject;
}
