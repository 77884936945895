let PublicMarketCompare = {

    Init : () => {
        PublicMarketCompare.NavScroll();
        PublicMarketCompare.CheckBox();
        PublicMarketCompare.SectionNav();
    },

    SectionNav : () => {

        window.addEventListener("scroll", (event) => {

            if (window.pageYOffset >= 280) {
                $('#compare_sticky_nav').removeClass('compare-sticky-nav-hidden');
            } else {
                $('#compare_sticky_nav').addClass('compare-sticky-nav-hidden');
            }
        });
    },

    CheckBox : () => {

        $d.off('change', 'input.response-selected').on('change', 'input.response-selected', function(){
            let checked = $(this).prop('checked'), dataKey = $(this).attr('data-key');
            $('input.-response-selected[data-key="'+dataKey+'"]').prop('checked', checked);
        });

        $d.off('change', 'input.-response-selected').on('change', 'input.-response-selected', function(){
            let checked = $(this).prop('checked'), dataKey = $(this).attr('data-key');
            $('input.response-selected[data-key="'+dataKey+'"]').prop('checked', checked);
        });
    },

    NavScroll : () => {

        let currentLeft = $('#compare_col_data').position().left;

        $('#compare_col_data').on('scroll', function(e) {

            let scrollX = e.currentTarget.scrollLeft;

            if (scrollX > currentLeft) {
                $('#_compare_col_data').animate({scrollLeft: scrollX }, 0);
            }
            if (scrollX < currentLeft) {
                $('#_compare_col_data').animate({scrollLeft: scrollX }, 0);
            }
            currentLeft = scrollX;
        });

        $('#_compare_col_data').on('scroll', function(e) {

            let scrollX = e.currentTarget.scrollLeft;

            if (scrollX > currentLeft) {
                $('#compare_col_data').animate({scrollLeft: scrollX }, 0);
            }
            if (scrollX < currentLeft) {
                $('#compare_col_data').animate({scrollLeft: scrollX }, 0);
            }
            currentLeft = scrollX;
        });

        $d.off('click', '#compare_scroll_left, #_compare_scroll_left').on('click', '#compare_scroll_left, #_compare_scroll_left', function(){
            $('#compare_col_data').animate({scrollLeft:'-=500'}, 500);
            $('#_compare_col_data').animate({scrollLeft:'-=500'}, 500);
        });

        $d.off('click', '#compare_scroll_right, #_compare_scroll_right').on('click', '#compare_scroll_right, #_compare_scroll_right', function(){
            $('#compare_col_data').animate({scrollLeft:'+=500'}, 500);
            $('#_compare_col_data').animate({scrollLeft:'+=500'}, 500);
        });
    }
}
module.exports = PublicMarketCompare;
