let ModSw = {

    init : () => {
        if('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw.js').then(function(reg) { 
                //console.log("*** |Service Worker Registered| ***", reg); 
            }).catch(function(error){
                //console.log("*** |Service worker not registered| ***", error)
            });
        }
    }
}
module.exports = ModSw;