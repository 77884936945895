let Prompt = {
    init: () => {
        let elements = document.getElementsByClassName('prompt-js');
        Array.from(elements).forEach(function(element) {
            element.addEventListener('click', Prompt.show);
        });
    },
    show: () => {
        event.preventDefault();

        let element = event.target;
        let message = element.dataset.message;
        let answer = element.dataset.answer;
        let required = element.dataset.required;

        if (undefined == message) {
            message = 'Commentaire';
        }
        if (undefined == answer) {
            answer = '';
        }
        if (undefined == required) {
            required = false;
        }
        if (required) {
            message+= ' obligatoire';
        }

        let value = prompt(message, answer);

        if (null != value) {
            if (required && 0 == value.length) {
                alert(message);

                return false;
            }

            let href = element.href;
            if (value && value.length) {
                href+= '&prompt=' + (encodeURIComponent(value));
            }

            document.location = href;
        }

        // cancel button
        return false;
    },
}
module.exports = Prompt;
