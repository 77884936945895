let FormEditor = {

    InlineStyle : (Quill) => {

        var DirectionAttribute = Quill.import('attributors/attribute/direction');
        Quill.register(DirectionAttribute,true);

        var AlignClass = Quill.import('attributors/class/align');
        Quill.register(AlignClass,true);

        var BackgroundClass = Quill.import('attributors/class/background');
        Quill.register(BackgroundClass,true);

        var ColorClass = Quill.import('attributors/class/color');
        Quill.register(ColorClass,true);

        var DirectionClass = Quill.import('attributors/class/direction');
        Quill.register(DirectionClass,true);

        var FontClass = Quill.import('attributors/class/font');
        Quill.register(FontClass,true);

        var SizeClass = Quill.import('attributors/class/size');
        Quill.register(SizeClass,true);

        var AlignStyle = Quill.import('attributors/style/align');
        Quill.register(AlignStyle,true);

        var BackgroundStyle = Quill.import('attributors/style/background');
        Quill.register(BackgroundStyle,true);

        var ColorStyle = Quill.import('attributors/style/color');
        Quill.register(ColorStyle,true);

        var DirectionStyle = Quill.import('attributors/style/direction');
        Quill.register(DirectionStyle,true);

        var FontStyle = Quill.import('attributors/style/font');
        Quill.register(FontStyle,true);

        var SizeStyle = Quill.import('attributors/style/size');
        Quill.register(SizeStyle,true);

        return Quill;

    },

    init : (ElementId) => {
        let Quill = require('quill');
            Quill = FormEditor.InlineStyle(Quill);

        let Editor = new Quill(ElementId, FormEditor.Options(Quill));
        // Add image
        Editor.getModule('toolbar').addHandler('image', (e) => {

            console.log(e);

            FormEditor.SelectLocalImage(Editor);
        });
        return Editor;
    },

    SelectLocalImage : (Editor)=> {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();
        input.onchange = () => {
          const file = input.files[0];
          if (/^image\//.test(file.type)) {
            FormEditor.SaveToServer(Editor, file);
          } else {
            console.warn('You could only upload images.');
          }
        };
    },

    UrlUpload : () => {
        if (
            $('body').attr('id') === 'modal' || 
            $('body').attr('id') === 'admin_modal_edit'
        ) {
            return '/admin/modal/editor/upload/image';
        }
    },

    SaveToServer: (Editor, file) => {
        const fd = new FormData();
        fd.append('image', file);
        FormEditor.AjaxUploadImage(Editor, fd);
    },

    AjaxUploadImage : (Editor, fd) => {

        let Ajax = $.ajax({
            url : FormEditor.UrlUpload(),
            method: 'POST',
            data : fd,
            processData: false,
            contentType: false,
        });
        Ajax.done(function(result){
            if (result && result.response == 'success') {
                const range = Editor.getSelection();
                Editor.insertEmbed(range.index, 'image', result.data.image_path);
            }
        });
        Ajax.fail(function(error){ console.log(error); });
    },

    Options : (Quill) => {
        return {
            debug: false,
            modules: {
                'syntax': false,
                'toolbar': FormEditor.ToolbarFull(Quill)
            },
            placeholder: 'Compose an epic...',
            readOnly: false,
            theme: 'snow'
        }
    },

    ToolbarFull : (Quill) => {
        /*return [
            [{ 'font': [] }, { 'size': [] }],
            [ 'bold', 'italic', 'underline', 'strike' ],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'script': 'super' }, { 'script': 'sub' }],
            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
            [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
            [ 'direction', { 'align': [] }],
            [ 'link', 'image', 'video', 'formula' ],
            [ 'clean' ]
        ]*/
        var Size = Quill.import('attributors/style/size');
        Size.whitelist = ['8px', '9px', '10px', '11px', '12px', '13px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '34px', '38px', '42px'];
        Quill.register(Size, true);

        return [
            [{ 'size': Size.whitelist }, 'bold', 'italic', 'underline', 'strike', { 'color': [] }, { 'list': 'ordered' }, { 'list': 'bullet'}, { 'align': [] }, 'link', 'image', 'video']
        ]
    }
}
module.exports = FormEditor;