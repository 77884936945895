let ResponsePublicMarket = {
    msnry : false,
    vehicleMax : 0,
    driverMax : 0,
    Init : () => {
        ResponsePublicMarket.VehiclesView(function(result){});
        ResponsePublicMarket.DriversView(function(result){});
        ResponsePublicMarket.VehiclesDriversViewChange();
        ResponsePublicMarket.EnergyColumn();
        ResponsePublicMarket.DeleteEnergyColumn();
        ResponsePublicMarket.FieldNavCol();
        ResponsePublicMarket.ResponseLot();
        ResponsePublicMarket.CellCalc();
        ResponsePublicMarket.SectionNav();
        ResponsePublicMarket.FormOnSubmit();
        ResponsePublicMarket.InputTextTime();
        ResponsePublicMarket.GlobalComment();
    },

    GlobalComment : () => {

        $('#response_form_global_comment').val($('#global_comment').val());

        $d.off('change', '#global_comment').on('change', '#global_comment', function(){
            $('#response_form_global_comment').val($(this).val());
        });
    },

    DriversView : (callback) => {

        // LABEL
        $('.flex-label-driver-title').addClass('flex-label-driver-title-hidden');
        $('.flex-label-driver').addClass('flex-label-driver-hidden');
        // FIELD
        $('.field-row-value-driver-empty').addClass('field-row-value-driver-empty-hidden');
        $('.field-row-value-driver').addClass('field-row-value-driver-hidden');
        $('.field-row-value-driver').addClass('driver-visibility-hidden');

        let itemDriverNumberLength = $('input.responses-drivers-number').length;
        let count = 0;

        $('input.responses-drivers-number').each(function(index, item){

            let value = parseInt($(item).val());
            let lotId = parseInt($(item).attr('lot-id'));
            let energyId = parseInt($(item).attr('energy-id'));

            if (value > 0) {

                ResponsePublicMarket.driverMax = value;

                for (var i=0; i < value; i++) {
                    // LABEL
                    $('.flex-label-driver-title[data-index="'+(i+1)+'"]').removeClass('flex-label-driver-title-hidden');
                    $('.flex-label-driver[data-index="'+(i+1)+'"]').removeClass('flex-label-driver-hidden');
                    // FIELD
                    $('.field-row-value-driver-empty[data-index="'+(i+1)+'"]').removeClass('field-row-value-driver-empty-hidden');
                    $('.field-row-value-driver[data-index="'+(i+1)+'"]').removeClass('field-row-value-driver-hidden');
                    $('.field-row-value-driver[lot-id="'+lotId+'"][energy-id="'+energyId+'"][data-index="'+(i+1)+'"]').removeClass('driver-visibility-hidden');
                }

            } else {
                if (ResponsePublicMarket.driverMax < 1) {
                    // LABEL
                    $('.flex-label-driver-title').addClass('flex-label-driver-title-hidden');
                    $('.flex-label-driver').addClass('flex-label-driver-hidden');
                    // FIELD
                    $('.field-row-value-driver-empty').addClass('field-row-value-driver-empty-hidden');
                    $('.field-row-value-driver').addClass('field-row-value-driver-hidden');
                    $('.field-row-value-driver').addClass('driver-visibility-hidden');
                }
            }

            count++;

            if (count === itemDriverNumberLength) {
                return callback(true);
            }
        });
    },

    DriverCleanFields : (lotId, energyId) => {
        setTimeout(function(){
            $('.driver-visibility-hidden[lot-id="'+lotId+'"][energy-id="'+energyId+'"]').each(function(index, item){
                let dataindex = parseInt($(item).attr('data-index')) - 1;
                $('#'+lotId+'_'+energyId+'_drivers_data_'+dataindex+'_rating').prop('selectedIndex',0);
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][seniority]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][start_city]"]').val('').attr('value', '');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][weekly_kilometers]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][time_approach]"]').val('000:00').attr('value', '000:00');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][time_handling]"]').val('000:00').attr('value', '000:00');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][time_driving]"]').val('000:00').attr('value', '000:00');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][time_total]"]').val('000:00').attr('value', '000:00');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][time_approach_hours]"]').val('000').attr('value', '000');
                $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+dataindex+'][time_approach_minutes]"]').val('00').attr('value', '00');
            });
        }, 1000);
    },

    VehiclesView : (callback) => {

        // LABEL
        $('.flex-label-vehicle-title').addClass('flex-label-vehicle-title-hidden');
        $('.flex-label-vehicle').addClass('flex-label-vehicle-hidden');
        // FIELD
        $('.field-row-value-vehicle-empty').addClass('field-row-value-vehicle-empty-hidden');
        $('.field-row-value-vehicle').addClass('field-row-value-vehicle-hidden');
        $('.field-row-value-vehicle').addClass('vehicle-visibility-hidden');

        let itemVehicleNumberLength = $('input.responses-vehicles-number').length;
        let count = 0;

        $('input.responses-vehicles-number').each(function(index, item){

            let value = parseInt($(item).val());
            let lotId = parseInt($(item).attr('lot-id'));
            let energyId = parseInt($(item).attr('energy-id'));

            if (value > 0) {

                ResponsePublicMarket.vehicleMax = value;

                for (var i=0; i < value; i++) {

                    // LABEL
                    $('.flex-label-vehicle-title[data-index="'+(i+1)+'"]').removeClass('flex-label-vehicle-title-hidden');
                    $('.flex-label-vehicle[data-index="'+(i+1)+'"]').removeClass('flex-label-vehicle-hidden');
                    // FIELD
                    $('.field-row-value-vehicle-empty[data-index="'+(i+1)+'"]').removeClass('field-row-value-vehicle-empty-hidden');
                    $('.field-row-value-vehicle[data-index="'+(i+1)+'"]').removeClass('field-row-value-vehicle-hidden');
                    $('.field-row-value-vehicle[lot-id="'+lotId+'"][energy-id="'+energyId+'"][data-index="'+(i+1)+'"]').removeClass('vehicle-visibility-hidden');
                }
            } else {
                if (ResponsePublicMarket.vehicleMax < 1) {
                    // LABEL
                    $('.flex-label-vehicle-title').addClass('flex-label-vehicle-title-hidden');
                    $('.flex-label-vehicle').addClass('flex-label-vehicle-hidden');
                    // FIELD
                    $('.field-row-value-vehicle-empty').addClass('field-row-value-vehicle-empty-hidden');
                    $('.field-row-value-vehicle').addClass('field-row-value-vehicle-hidden');
                    $('.field-row-value-vehicle').addClass('vehicle-visibility-hidden');
                }
            }

            count++;

            if (count === itemVehicleNumberLength) {
                return callback(true);
            }
        });
    },

    VehicleCleanFields : (lotId, energyId) => {

        setTimeout(function(){
            $('.driver-visibility-hidden[lot-id="'+lotId+'"][energy-id="'+energyId+'"]').each(function(index, item){
                let dataindex = parseInt($(item).attr('data-index')) - 1;
                $('#'+lotId+'_'+energyId+'_vehicles_data_'+dataindex+'_type').prop('selectedIndex',0);
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][city_start]"]').val('').attr('value', '');
                $('#'+lotId+'_'+energyId+'_vehicles_data_'+dataindex+'_approach_kilometers_first_lading').prop('selectedIndex',0);
                $('#'+lotId+'_'+energyId+'_vehicles_data_'+dataindex+'_van').prop('selectedIndex',0);
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][truck_brand]"]').val('').attr('value', '');
                $('#'+lotId+'_'+energyId+'_vehicles_data_'+dataindex+'_tailgate').prop('selectedIndex',0);
                $('#'+lotId+'_'+energyId+'_vehicles_data_'+dataindex+'_euro_standard').prop('selectedIndex',0);
                $('#'+lotId+'_'+energyId+'_vehicles_data_'+dataindex+'_public_release').prop('selectedIndex',0);
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][kilometers_counter]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][truck_size_length]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][truck_size_width]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][truck_size_height]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][overall_size_length]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][overall_size_height]"]').val('0').attr('value', '0');
                $('#'+lotId+'_'+energyId+'_vehicles_data_'+dataindex+'_tailer_age').prop('selectedIndex',0);
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][tailer_size_length]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][number_drivers_in_swap_body]"]').val('0').attr('value', '0');
                $('input[name="responses['+lotId+']['+energyId+'][vehicles_data]['+dataindex+'][number_of_swap_bodies]"]').val('0').attr('value', '0');
            });
        }, 1000);
    },

    VehiclesDriversViewChange : () => {

        let $that = null;
        let $oldValue = 1;

        $d.off('change', 'input.responses-drivers-number').on('change', 'input.responses-drivers-number', function(){

            let lotId           = $(this).attr('lot-id');
            let energyId        = $(this).attr('energy-id');
            let value           = parseInt($(this).val());

            let currentTooltip      = $('.js-drivers-tooltip').children('.as-tooltip').attr('data-tooltip');
            let splitCurrentCell    = currentTooltip.replace(' ', '').split('+');
            let countCurrentCell    = splitCurrentCell.length;
            $that = $(this);

            // >> tooltip update
            let datatooltip = 'C8';
            $oldValue = countCurrentCell;

            if (countCurrentCell > value) {

                $('#response_form_confirm_modal').show();

                $d.off('click', '.js-content-btn-confirm').on('click', '.js-content-btn-confirm', function(){

                    $('#response_form_confirm_modal').hide();

                    if (value > 0) {
                        for (var i= 1; i <= value; i++) {
                            if (i > 1) {
                                datatooltip += ' + C'+i*8;
                            } else {
                                datatooltip = 'C'+i*8;
                            }
                        }
                    }
                    $('.js-drivers-tooltip').children('.as-tooltip').attr('data-tooltip', datatooltip);

                    ResponsePublicMarket.DriversView(function(result){
                        ResponsePublicMarket.DriverCleanFields(lotId, energyId);
                    });

                    // >> Update time total
                    let fieldName = 'responses['+lotId+']['+energyId+'][drivers_data][0][time_driving]';
                    let numField = 0;
                    ResponsePublicMarket.DriverServiceTime(fieldName, lotId, energyId, numField);
                });
            } else {
                ResponsePublicMarket.DriversView(function(result){
                    ResponsePublicMarket.DriverCleanFields(lotId, energyId);
                });

                if (value > 0) {
                    for (var i= 1; i <= value; i++) {
                        if (i > 1) {
                            datatooltip += ' + C'+i*8;
                        } else {
                            datatooltip = 'C'+i*8;
                        }
                    }
                }
                $('.js-drivers-tooltip').children('.as-tooltip').attr('data-tooltip', datatooltip);
            }
        });

        $d.off('change', 'input.responses-vehicles-number').on('change', 'input.responses-vehicles-number', function(){

            let lotId           = $(this).attr('lot-id');
            let energyId        = $(this).attr('energy-id');
            let value           = parseInt($(this).val());

            let beforeValue = $('.field-row-value-vehicle-empty[lot-id="' + lotId + '"][energy-id="'+energyId+'"] + .field-row-value-vehicle:not(.vehicle-visibility-hidden)').length;
            $that = $(this);

            $oldValue = beforeValue;

            if (beforeValue > value) {

                $('#response_form_confirm_modal').show();

                $d.off('click', '.js-content-btn-confirm').on('click', '.js-content-btn-confirm', function(){

                    $('#response_form_confirm_modal').hide();

                    ResponsePublicMarket.VehiclesView(function(result){
                        ResponsePublicMarket.VehicleCleanFields(lotId, energyId);
                    });
                    $(this).attr('before-value', value);
                });

            } else {
                ResponsePublicMarket.VehiclesView(function(result){});
                $(this).attr('before-value', value);
            }
        });

        $d.off('click', '.js-content-btn-cancel').on('click', '.js-content-btn-cancel', function(){
            $('#response_form_confirm_modal').hide();
            ResponsePublicMarket.DriverVehicleChangeValue($that, $oldValue);
        });
    },

    DriverVehicleChangeValue : ($that, value) => {
        $that.val(value).attr('value', value);
    },

    FieldNavColScroll : (position) => {

        let left = position.left, scrollTo = left - 400;

        if (scrollTo > 0) {
            $('#col_flex_field_container, #lot_nav_sticky_items').animate({scrollLeft:'+='+scrollTo}, 500);
        }
        if (scrollTo < 0) {
            scrollTo = Math.abs(scrollTo);
            $('#col_flex_field_container, #lot_nav_sticky_items').animate({scrollLeft:'-='+scrollTo}, 500);
        }
    },

    DeleteEnergyColumn : () => {
        $d.off('click', '.deleteColumnLink').on('click', '.deleteColumnLink', function() {
            const element = $(this).parent().parent(); // récupérer sticky header
            const lot_id = $(element).attr('lot-id');
            const energy_id = $(element).attr('energy-id');
            if (lot_id && energy_id) {
                $(`#col_flex_field_container > div > div.col-flex-field-row[lot-id=${lot_id}][energy-id=${energy_id}]`).remove();
                $(`#lot_nav_sticky_items > div > div.field-row-lot-container[lot-id=${lot_id}][energy-id=${energy_id}]`).remove();
            }
        });
    },

    EnergyColumn : () => {
        $d.off('click', '#add_column_energy_button').on('click', '#add_column_energy_button', function() {
            const publicmarket_id = $("input:hidden[name=publicmarket_id]").val();
            const lot_id = $("#col_field_lot_select").val();
            const energy_id = $("#col_field_energy_select").val();
            const subcontractor_id = $("input:hidden[name=do_response_form_subcontractor]").val();
            const turn_number = +$("input:hidden[name=publicmarket_turn_number]").val();
            if (turn_number == 1) {
                if (!isNaN(publicmarket_id) && !isNaN(lot_id) && !isNaN(energy_id)) {
                    if ($(`#col_flex_field_container > div > div.col-flex-field-row[lot-id=${lot_id}][energy-id=${energy_id}]`).length) {
                        alert("Une réponse existe déjà pour le lot et l'énergie sélectionnés.");
                    } else if ($('#isGenericSTT').val() && $(`#col_flex_field_container > div > div.col-flex-field-row[lot-id=${lot_id}]`).length) {
                        // Colonne estimation, création d'une réponse générique.
                        alert("Vous ne pouvez saisir qu'une seule réponse par lot en tant qu'estimation.");
                    } else {
                        $('#global_loader').removeClass("global-loader-hidden").show();
                        $.ajax({
                            url: `/publicmarket/response/form/column/${publicmarket_id}/${lot_id}/${energy_id}/${subcontractor_id}`,
                            method: 'POST',
                        })
                            .catch(function () {
                                alert("Impossible d'ajouter la colonne.");
                            })
                            .then(function (data) {
                                $('#global_loader').hide();
                                if (data) {
                                    const html = data;
                                    const headerString = $(html).find("div.field-row-label").html();
                                    const headerHtml = `<div lot-id="${lot_id}" energy-id="${energy_id}" class="field-row-lot-container"><div class="field-row-lot">${headerString}</div></div>`;
                                    if ($('#col_flex_field_container > div > div.col-flex-field-row').length) {
                                        // Insertion dans l'ordre ID Lot.
                                        let memIdLot = 0;
                                        let memIdEnergy = 0;
                                        $('#col_flex_field_container > div > div.col-flex-field-row').each(function (index, element) {
                                            if ($(element).attr("lot-id") >= memIdLot && $(element).attr("lot-id") <= lot_id) {
                                                memIdLot = $(element).attr("lot-id");
                                                memIdEnergy = $(element).attr("energy-id");
                                            }
                                        });

                                        if (memIdLot) {
                                            $(`#col_flex_field_container > div > div.col-flex-field-row[lot-id=${memIdLot}][energy-id=${memIdEnergy}]`).after(html);
                                            $(`#lot_nav_sticky_items > div > div.field-row-lot-container[lot-id=${memIdLot}][energy-id=${memIdEnergy}]`).after(headerHtml);
                                            // Copie des informations de la colonne precedente du meme lot vers la nouvelle colonne.
                                            if (memIdLot == lot_id) {

                                                const excludeFields = ["[energy_id]", "[response_state]", "[responses_update]", "[check_changes]"];

                                                $('input[lot-id="' + memIdLot + '"][energy-id="' + memIdEnergy + '"], select[lot-id="' + memIdLot + '"][energy-id="' + memIdEnergy + '"]').each(function (key, input) {
                                                    const endKey = ($(input).attr('name')).replace('responses[' + memIdLot + '][' + memIdEnergy + ']', '');

                                                    if ($(this).val() && endKey && $.inArray(endKey, excludeFields) < 0) {
                                                        $(`[name=\"responses[${lot_id}][${energy_id}]${endKey}\"]`).val($(this).val());
                                                    }
                                                });
                                                alert("Les données saisies dans la dernière colonne du même lot ont été dupliquées automatiquement dans la nouvelle colonne (nouvelle énergie) créée, ajustez en fonction de l’énergie proposée, puis vérifiez ces informations avant de publier votre réponse.");
                                            }
                                        } else {
                                            $('#col_flex_field_container > div').prepend(html);
                                            $('#lot_nav_sticky_items > div').prepend(headerHtml);
                                        }
                                    } else {
                                        $('#col_flex_field_container > div').prepend(html);
                                        $('#lot_nav_sticky_items > div').prepend(headerHtml);
                                    }
                                }
                                const position = $('[id^=col_lot_' + lot_id + '_ener_' + energy_id + ']').position();
                                if (position) {
                                    ResponsePublicMarket.FieldNavColScroll(position);
                                }
                                ResponsePublicMarket.VehiclesView(function (result) {
                                });
                                ResponsePublicMarket.DriversView(function (result) {
                                });
                                window.AndCopyPaste.initInputsMap();
                            });
                    }
                } else {
                    alert("Veuillez sélectionner un lot et une énergie.");
                }
            } else {
                alert("Vous ne pouvez pas ajouter de nouvelle réponse au delà du premier tour.");
            }
        });
    },

    FieldNavCol : () => {

        let currentLeft = $('#col_flex_field_container').position().left;

        $('#col_flex_field_container').on('scroll', function(e) {

            let scrollX = e.currentTarget.scrollLeft;

            if (scrollX > currentLeft) {
                $('#lot_nav_sticky_items').animate({scrollLeft: scrollX }, 0);
            }
            if (scrollX < currentLeft) {
                $('#lot_nav_sticky_items').animate({scrollLeft: scrollX }, 0);
            }
            currentLeft = scrollX;
        });

        $('#lot_nav_sticky_items').on('scroll', function(e) {

            let scrollX = e.currentTarget.scrollLeft;

            if (scrollX > currentLeft) {
                $('#col_flex_field_container').animate({scrollLeft: scrollX }, 0);
            }
            if (scrollX < currentLeft) {
                $('#col_flex_field_container').animate({scrollLeft: scrollX }, 0);
            }
            currentLeft = scrollX;
        });

        $d.off('click', '#col_field_nav_left, #_col_field_nav_left').on('click', '#col_field_nav_left, #_col_field_nav_left', function(){
            $('#col_flex_field_container, #lot_nav_sticky_items').animate({scrollLeft:'-=500'}, 500);
        });

        $d.off('click', '#col_field_nav_right, #_col_field_nav_right').on('click', '#col_field_nav_right, #_col_field_nav_right', function(){
            $('#col_flex_field_container, #lot_nav_sticky_items').animate({scrollLeft:'+=500'}, 500);
        });

        $d.off('change', '#col_field_lot_select, #_col_field_lot_select').on('change', '#col_field_lot_select, #_col_field_lot_select', function(){
            
            let lotId = $(this).val();

            if (!isNaN(lotId) && $('[id^=col_lot_'+lotId+']').length) {
                let position    = $('[id^=col_lot_'+lotId+']').first().position();
                ResponsePublicMarket.FieldNavColScroll(position);
            } else if($('.col-flex-field-row').length) {
                let position    = $('.col-flex-field-row').first().position();
                ResponsePublicMarket.FieldNavColScroll(position);
            }
        });
    },

    InputTextTime : () => {

        $d.off('change', '.hours-cel-number').on('change', '.hours-cel-number', function(){

            let hours = $(this).val();
            let lotId = $(this).attr('lot-id');
            let energyId = $(this).attr('energy-id');

            if (hours.length > 3) {
                hours = hours.substring(0, 3);
            }

            if (parseInt(hours) > 999) {
                minutes = '999';
            }

            if (hours.length < 1) {
                $(this).val('000').attr('value', '000');
            } else if (hours.length === 1) {
                $(this).val('00'+hours).attr('value', '00'+hours);
            } else if (hours.length === 2) {
                $(this).val('0'+hours).attr('value', '0'+hours);
            } else {
                $(this).val(hours).attr('value', hours);
            }

            hours = $(this).val();

            let id = $(this).attr('id');
                id = id.replace('_hours', '')

            let minutes = $('#'+id+'_minutes').val();

            let timeTotal = hours+':'+minutes;

            $('#'+id).val(timeTotal).attr('value', timeTotal);

            if (id.indexOf('drivers_data') != -1) {
                let numField = $(this).attr('num-field');
                ResponsePublicMarket.DriverServiceTime(id, lotId, energyId, numField);
            } else {
                ResponsePublicMarket.ServiceCalc(lotId, energyId);
            }
        });

        $d.off('change', '.minutes-cel-number').on('change', '.minutes-cel-number', function(){

            let minutes = $(this).val();
            let lotId = $(this).attr('lot-id');
            let energyId = $(this).attr('energy-id');

            if (minutes.length > 2) {
                minutes = minutes.substring(0, 2);
            }

            if (parseInt(minutes) > 59) {
                minutes = '59';
            }

            if (minutes.length < 1) {
                $(this).val('00').attr('value', '00');
            } else if (minutes.length === 1) {
                $(this).val('0'+minutes).attr('value', '0'+minutes);
            } else {
                $(this).val(minutes).attr('value', minutes);
            }

            minutes = $(this).val();

            let id = $(this).attr('id');
                id = id.replace('_minutes', '')

            let hours = $('#'+id+'_hours').val();

            let timeTotal = hours+':'+minutes;

            $('#'+id).val(timeTotal).attr('value', timeTotal);

            if (id.indexOf('drivers_data') != -1) {
                let numField = $(this).attr('num-field');
                ResponsePublicMarket.DriverServiceTime(id, lotId, energyId, numField);
            } else {
                ResponsePublicMarket.ServiceCalc(lotId, energyId);
            }
        });
    },

    FormOnSubmit : () => {

        $d.off('click', '#response_form_subcontracor_publish_all').on('click', '#response_form_subcontracor_publish_all', function(e){
            e.preventDefault();
            $('#global_loader').removeClass('global-loader-hidden');
            ResponsePublicMarket.JsonFormData(function(datas){
                if (datas) {
                    let jsonData = JSON.stringify(datas);
                    $('#response_form_subcontractor_post_publish_all').val('1').attr('value', '1');;
                    $('#response_form_json').val(jsonData).attr('value', jsonData);
                    $('#response_form_subcontracor_hidden').trigger('submit');
                    return;
                } else {
                    alert('error JSON FormData');
                    return;
                }
            });
        });

        $d.off('click', '#form_submit').on('click', '#form_submit', function(e){
            e.preventDefault();
            $('#global_loader').removeClass('global-loader-hidden');
            ResponsePublicMarket.JsonFormData(function(datas){
                if (datas) {
                    let jsonData = JSON.stringify(datas);
                    $('#response_form_json').val(jsonData).attr('value', jsonData);
                    $('#response_form_subcontracor_hidden').trigger('submit');
                    return;
                } else {
                    alert('error JSON FormData');
                    return;
                }
            });
        });
    },

    checkOnchange : (lotId, energyId) => {
        let $input = $('input[name="responses['+lotId+']['+energyId+'][check_changes]"]');
        if (parseInt($input.val()) == 1){
            return true;
        }
        return false;
    },

    JsonFormData : (callback) => {

        let datas = [], nbItem = $('.year-total-price-indirect-costs').length, count = 0;
        $('.year-total-price-indirect-costs').each(function(index, item){
            let value = $(this).val(), lotId = $(this).attr('lot-id'), energyId = $(this).attr('energy-id');

            if ($('select[name="responses['+lotId+']['+energyId+'][response_state]"]').val() != "out") {
                if (!datas[lotId]) {
                    datas[lotId] = [];
                }
                datas[lotId][energyId] = ResponsePublicMarket.FormGlobalDatas();
                $('input[lot-id="' + lotId + '"][energy-id="' + energyId + '"], select[lot-id="' + lotId + '"][energy-id="' + energyId + '"]').each(function (key, input) {
                    let inputValue = $(input).val() ? $(input).val() : '';
                    let inputKey = ($(input).attr('name')).replace('responses[' + lotId + '][' + energyId + '][', '');
                    inputKey = inputKey.replace(']', '');

                    if (inputKey != 'undefined' && inputKey != 'vehicles_data' && inputKey.indexOf('vehicles_data') == -1 && inputKey != 'drivers_data' && inputKey.indexOf('drivers_data') == -1) {
                        datas[lotId][energyId][inputKey] = inputValue;
                    }
                    if (inputKey.indexOf('vehicles_data') != -1) {
                        let vehicleNumber = parseInt($(`#${lotId}_${energyId}_vehicles`).val());
                        if (vehicleNumber > 0) {
                            for (var i = 0; i < vehicleNumber; i++) {
                                datas[lotId][energyId]['vehicles_data'][i] = ResponsePublicMarket.FormVehiclesDataJSON(lotId, energyId, i);
                            }
                        }
                    }
                    if (inputKey.indexOf('drivers_data') != -1) {
                        let driverNumber = parseInt($(`#${lotId}_${energyId}_drivers`).val());
                        if (driverNumber > 0) {
                            for (var i = 0; i < driverNumber; i++) {
                                datas[lotId][energyId]['drivers_data'][i] = ResponsePublicMarket.FormDriversDataJSON(lotId, energyId, i);
                            }
                        }
                    }
                });
            }
            count++;
            if (nbItem === count) {
                return callback(datas);
            }
        });
    },

    FormGlobalDatas : () => {
        return {
            "responses_update" : "",
            "lot_title" : "",
            "lot_id" : "",
            "society_name" : "",
            "siret" : "",
            "email" : "",
            "last_name" : "",
            "phone" : "",
            "year_kilometers" : "",
            "shipping_agent" : "",
            "year_kilometers_carrier" : "",
            "kilometers_cost_fuel" : "",
            "kilometers_cost_tires" : "",
            "kilometers_cost_maintenance" : "",
            "kilometers_cost_road" : "",
            "kilometers_cost" : "",
            "human_resources_salaries_and_charges" : "",
            "special_calc_daytime_hourly_rate" : "",
            "special_calc_nighttime_hourly_rate" : "",
            "human_resources_road_costs" : "",
            "human_resources" : "",
            "vehicle_subcost_insurance" : "",
            "vehicle_subcost_tax" : "",
            "vehicle_subcost_amortisation" : "",
            "vehicle_subcost_financing" : "",
            "vehicle_subcost" : "",
            "structure_and_margin_indirect_costs" : "",
            "year_total_price_indirect_costs" : "",
            "cost_price_kilometers" : "",
            "carrier_name" : "",
            "carrier_email" : "",
            "carrier_phone" : "",
            "infrastucture_tun_use" : "",
            "infrastucture_internal_workshop" : "",
            "subcontracting_of_link" : "",
            "subcontracting_of_link_name" : "",
            "control_type" : "",
            "control_substitution_vehicle" : "",
            "control_substitution_vehicle_location_start" : "",
            "control_substitution_driver" : "",
            "control_substitution_driver_location_start" : "",
            "control_reuse_on_another_service" : "",
            "highway_city_to_city" : "",
            "relay_organisation" : "",
            "relay_organisation_location" : "",
            "vehicles" : "",
            "vehicles_data" : [],
            "drivers" : "",
            "drivers_data" : [],
            "services_time_total_driving" : "",
            "service_time_day" : "000:00",
            "service_time_day_hours" : "000",
            "service_time_day_minutes" : "00",
            "service_time_day_hundredth" : "",
            "service_time_night" : "000:00",
            "service_time_night_hours" : "000",
            "service_time_night_minutes" : "000",
            "service_time_night_hundredth" : "",
            "service_time_total_hundredth" : "",
            "number_meal_allowances" : "",
            "number_of_single_meal_allowances" : "",
            "number_of_single_night_meal_allowances" : "",
            "number_of_breakage_allowances" : "",
            "number_of_special_allowances" : "",
            "one_meal_one_overnight_stay" : "",
            "two_meal_one_overnight_stay" : "",
            "travel_expenses_other" : "",
            "total_annual_road_costs" : "",
            "commentary_organisation" : "",
            "additional_links" : "",
            "general_comment" : "",
            "response_state" : ""
        }
    },

    FormDriversDataJSON : (lotId, energyId, i) => {
        return {
            "rating" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_rating').val(),
            "seniority" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_seniority').val(),
            "start_city" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_start_city').val(),
            "weekly_kilometers" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_weekly_kilometers').val(),
            "time_approach" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_approach').val(),
            "time_approach_hours" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'time_approach_hours').val(),
            "time_approach_minutes" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'time_approach_minutes').val(),
            "time_handling" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_handling').val(),
            "time_handling_hours" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_handling_hours').val(),
            "time_handling_minutes" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_handling_minutes').val(),
            "time_driving" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_driving').val(),
            "time_driving_hours" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_driving_hours').val(),
            "time_driving_minutes" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_driving_minutes').val(),
            "time_total" : $('#'+lotId+'_'+energyId+'_drivers_data_'+i+'_time_total').val(),
        }
    },

    FormVehiclesDataJSON : (lotId, energyId, i) => {
        return {
            "type" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_type').val(),
            "city_start" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_city_start').val(),
            "approach_kilometers_first_lading" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_approach_kilometers_first_lading').val(),
            "van" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_van').val(),
            "truck_brand" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_truck_brand').val(),
            "tailgate" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_tailgate').val(),
            "euro_standard" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_euro_standard').val(),
            "public_release" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_public_release').val(),
            "kilometers_counter" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_kilometers_counter').val(),
            "truck_size_length" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_truck_size_length').val(),
            "truck_size_width" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_truck_size_width').val(),
            "truck_size_height" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_truck_size_height').val(),
            "overall_size_length" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_overall_size_length').val(),
            "overall_size_height" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_overall_size_height').val(),
            "tailer_age" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_tailer_age').val(),
            "tailer_size_length" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_tailer_size_length').val(),
            "number_drivers_in_swap_body" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_number_drivers_in_swap_body').val(),
            "number_of_swap_bodies" : $('#'+lotId+'_'+energyId+'_vehicles_data_'+i+'_number_of_swap_bodies').val(),
        }
    },

    SectionNav : () => {

        window.addEventListener("scroll", (event) => {

            if (window.pageYOffset >= 200) {
                $('#section_nav_container').addClass('section-nav-fixed');
            } else {
                $('#section_nav_container').removeClass('section-nav-fixed');
            }

            if (window.pageYOffset >= 550) {
                if ($('#response_lot_nav_sticky_container').hasClass('response-lot-nav-sticky-hidden')) {
                    $('#response_lot_nav_sticky_container').removeClass('response-lot-nav-sticky-hidden');
                    const position = $('#col_flex_field_container').scrollLeft();
                    $('#lot_nav_sticky_items').scrollLeft(position);
                }
            } else {
                $('#response_lot_nav_sticky_container').addClass('response-lot-nav-sticky-hidden');
            }
        });

        window.addEventListener("resize", (event) => {
            if (ResponsePublicMarket.msnry) ResponsePublicMarket.msnry.layout();
        });

        $d.off('click', '.section-nav-item').on('click', '.section-nav-item', function(){
            let id = $(this).attr('data-id');
            $('html,body').animate({scrollTop: ($('#'+id).offset().top - 150)}, 500);
        });
    },

    FormatNumber : (num)=> {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    },

    IsValidResponseLot : (lotId, energyId) => {

        $('input[name="responses['+lotId+']['+energyId+'][check_changes]"]').val('1').attr('value', '1');
        $('.field-row-lot-container[lot-id="'+lotId+'"][energy-id="'+energyId+'"]')
            .children('.field-row-lot')
            .addClass('field-row-lot-response')
        ;
        $('.col-flex-field-row[lot-id="'+lotId+'"][energy-id="'+energyId+'"]')
            .children('.field-row-label')
            .addClass('field-row-label-response')
        ;
        $('.col-flex-field-row[lot-id="'+lotId+'"][energy-id="'+energyId+'"]').addClass('col-flex-field-row-active');
    },

    IsNotValidResponseLot : (lotId, energyId) => {

        $('input[name="responses['+lotId+']['+energyId+'][check_changes]"]').val('0').attr('value', '0');
        $('.field-row-lot-container[lot-id="'+lotId+'"][energy-id="'+energyId+'"]')
            .children('.field-row-lot')
            .removeClass('field-row-lot-response')
        ;
        $('.col-flex-field-row[lot-id="'+lotId+'"][energy-id="'+energyId+'"]')
            .children('.field-row-label')
            .removeClass('field-row-label-response')
        ;
        $('.col-flex-field-row[lot-id="'+lotId+'"][energy-id="'+energyId+'"]').removeClass('col-flex-field-row-active');
    },

    CheckValidResponseLot : (lotId, energyId) => {

        ResponsePublicMarket.IsNotValidResponseLot(lotId, energyId);

        $('select[lot-id="'+lotId+'"][energy-id="'+energyId+'"], input[lot-id="'+lotId+'"][energy-id="'+energyId+'"]').each(function(index, item){
            
            let value = $(item).val(), type = $(item).attr('type'), name = $(item).attr('name');

            if (
                name && 
                name != 'responses['+lotId+']['+energyId+'][lot_title]' && 
                name != 'responses['+lotId+']['+energyId+'][year_kilometers]' && 
                name != 'responses['+lotId+']['+energyId+'][shipping_agent]' && 
                name != 'responses['+lotId+']['+energyId+'][response_state]' && 
                name != 'responses['+lotId+']['+energyId+'][responses_update]' && 
                name != 'responses['+lotId+']['+energyId+'][last_name]' && 
                name != 'responses['+lotId+']['+energyId+'][lot_id]' &&
                name != 'responses['+lotId+']['+energyId+'][energy_id]' &&
                name != 'responses['+lotId+']['+energyId+'][phone]' && 
                name != 'responses['+lotId+']['+energyId+'][society_name]' && 
                name != 'responses['+lotId+']['+energyId+'][siret]' && 
                name != 'responses['+lotId+']['+energyId+'][email]' && 
                name != 'responses['+lotId+']['+energyId+'][check_changes]' && 
                name != 'responses['+lotId+']['+energyId+'][drivers]' && 
                name != 'responses['+lotId+']['+energyId+'][vehicles]' && 
                name != 'responses['+lotId+']['+energyId+'][carrier_name]' && 
                name != 'responses['+lotId+']['+energyId+'][carrier_email]' && 
                name != 'responses['+lotId+']['+energyId+'][carrier_phone]'
            ) {
                let nodeName = item.nodeName;

                if (nodeName == 'INPUT') {

                    let inputType = $(item).attr('type');

                    if (inputType == 'number' && value != '' && value != '000' && value != '00' && value != '0' && value != '0,00' && value != '0.00' && value.length > 0) {
                        ResponsePublicMarket.IsValidResponseLot(lotId, energyId);
                    }
                    if (inputType == 'text') {
                        if (value != '000' && value != '00' && value != '000:00' && value != '00:00' && value != '' && value != '0' && value != '0,00' && value != '0.00' && value.length > 0) {
                            ResponsePublicMarket.IsValidResponseLot(lotId, energyId);
                        }
                    }
                    if (inputType == 'hidden') {
                        if (value != '000' && value != '00' && value != '000:00' && value != '00:00' && value != '' && value != '0' && value != '0,00' && value != '0.00' && value.length > 0) {
                            ResponsePublicMarket.IsValidResponseLot(lotId, energyId);
                        }
                    }
                    ResponsePublicMarket.ServiceCalc(lotId, energyId);

                } else if (nodeName == 'SELECT') {
                    if (value.length > 0 && value != '' && value != '-') {
                        ResponsePublicMarket.IsValidResponseLot(lotId, energyId);
                    }
                }
            }
        });
    },

    CellCalc : () => {
        /*if ($('.field-calc').length) {*/

            let Common = require('./Common');

            $d.off('change', 'input[type="text"][data-type="time"]').on('change', 'input[type="text"][data-type="time"]', function(){// Generic

                let $that       = $(this);
                let fieldName   = $that.attr('name');
                let lotId       = $that.attr('lot-id');
                let energyId    = $that.attr('energy-id');

                if (fieldName.indexOf('drivers_data') != -1) {
                    let numField = $that.attr('num-field');
                    ResponsePublicMarket.DriverServiceTime(fieldName, lotId, energyId, numField);
                }
            });

            $d.off('change', 'input[type="number"]').on('change', 'input[type="number"]', function() {
                return ResponsePublicMarket.NumberInputFormat($(this));
            });

            $d.off('change', 'input[type="text"][data-type="number"]').on('change', 'input[type="text"][data-type="number"]', function() {
                return ResponsePublicMarket.NumberInputFormat($(this));
            });

            $d.off('change', 'input.js-human-resources-salaries-and-charges').on('change', 'input.js-human-resources-salaries-and-charges', function(){
                let lotId = $(this).attr('lot-id')
                let energyId = $(this).attr('energy-id');
                ResponsePublicMarket.RHCalc(lotId, energyId);
                return false;
            });

            $d.off('change', 'input.js-service-time-day').on('change', 'input.js-service-time-day', function(){
                let lotId = $(this).attr('lot-id');
                let energyId = $(this).attr('energy-id');
                ResponsePublicMarket.ServiceCalc(lotId, energyId);
            });

            $d.off('change', 'input.js-service-time-night').on('change', 'input.js-service-time-night', function(){
                let lotId = $(this).attr('lot-id');
                let energyId = $(this).attr('energy-id');
                ResponsePublicMarket.ServiceCalc(lotId, energyId);
            });

            $d.off('change', 'input.js-year-kilometers').on('change', 'input.js-year-kilometers', function(){
                let lotId = $(this).attr('lot-id');
                let energyId = $(this).attr('energy-id');
                ResponsePublicMarket.IndirectCost(lotId, energyId);
            });

            $d.off('change', 'select, input').on('change', 'select, input', function(e){

                let lotId = $(this).attr('lot-id');
                let energyId = $(this).attr('energy-id');
                let value = $(this).val();
                let fieldName = $(this).attr('name');

                if (!$(this).hasClass('time-cel-number') && !$(this).hasClass('is-text')) {

                    if (fieldName) {
                        if (fieldName.indexOf('weekly_kilometers')) { // F6
                            value = value.length > 0 ? value : 0;
                            value = Common.numberFormat(value);
                        } else if (fieldName.indexOf('year_kilometers_carrier')) { // F8
                            value = value.length > 0 ? value : 0;
                            value = Common.numberFormat(value, 0);
                        } else if (fieldName.indexOf('kilometers_cost_fuel')) { // F9
                            value = value.length > 0 ? value : 0;
                            value = Common.numberFormat(value, 0);
                        } else if (fieldName.indexOf('kilometers_cost_tires')) { // F10
                            value = value.length > 0 ? value : 0;
                            value = Common.numberFormat(value, 0);
                        } else if (fieldName.indexOf('kilometers_cost_maintenance')) { // F11
                            value = value.length > 0 ? value : 0;
                            value = Common.numberFormat(value, 0);
                        } else if (fieldName.indexOf('kilometers_cost_road')) { // F12
                            value = value.length > 0 ? value : 0;
                            value = Common.numberFormat(value, 0);
                        }
                    }

                    $(this).val(value).attr('value', value);

                    ResponsePublicMarket.BasicCalcTotal('kilometers_cost', lotId, energyId);
                    ResponsePublicMarket.BasicCalcTotal('human_resources', lotId, energyId);
                    ResponsePublicMarket.BasicCalcTotal('vehicle_subcost', lotId, energyId);
                    ResponsePublicMarket.IndirectCost(lotId, energyId);
                    ResponsePublicMarket.RoadCost(lotId, energyId);
                    ResponsePublicMarket.RHCalc(lotId, energyId);
                    ResponsePublicMarket.ServiceCalc(lotId, energyId);

                    if (lotId) {
                        setTimeout(function() { 
                            ResponsePublicMarket.CheckValidResponseLot(lotId, energyId);
                        }, 500);
                    }

                } else if ($(this).hasClass('is-text')) {

                    $(this).val(value).attr('value', value);

                    if (lotId) {
                        setTimeout(function() { 
                            ResponsePublicMarket.CheckValidResponseLot(lotId, energyId);
                        }, 500);
                    }
                }
            });

            $d.off('focusin', 'select, input').on('focusin', 'select, input', function(e){
                $(this).trigger('select');
            });
       /* }*/
    },

    IndirectCost : (lotId, energyId) => {

        let Common = require('./Common');

        let $F26 = $(`#${lotId}_${energyId}_cost_price_kilometers`);

        let F13Val = parseFloat(ResponsePublicMarket.RebaseNumber($(`#${lotId}_${energyId}_kilometers_cost`).val()));
        let F18Val = parseFloat(ResponsePublicMarket.RebaseNumber($(`#${lotId}_${energyId}_human_resources`).val()));
        let F23Val = parseFloat(ResponsePublicMarket.RebaseNumber($(`#${lotId}_${energyId}_vehicle_subcost`).val()));
        let F24Val = parseFloat(ResponsePublicMarket.RebaseNumber($(`#${lotId}_${energyId}_structure_and_margin_indirect_costs`).val()));

        let ivalue = F13Val + F18Val + F23Val + F24Val;
            ivalue = Common.numberFormat(ivalue);

        $(`#${lotId}_${energyId}_year_total_price_indirect_costs`).val(ivalue);

        let F25Val  = parseFloat(ResponsePublicMarket.RebaseNumber($(`#${lotId}_${energyId}_year_total_price_indirect_costs`).val()));
        let F6Val   = parseFloat(ResponsePublicMarket.RebaseNumber($(`#${lotId}_${energyId}_year_kilometers`).val()));

        let F25F6Total = F25Val / F6Val;

        if (F25Val != 0 && F6Val != 0) {

            let value = F25F6Total.toFixed(4);
                value = Common.numberFormat(value, 4);

            if (value.length > 0) {
                $F26.val(value).attr('value', value);
            } else {
                $F26.val('0').attr('value', '0');
            }
        } else {
            $F26.val('0').attr('value', '0');
        }
    },

    DriverServiceTime: (fieldName, lotId, energyId, numField) => {

        if (fieldName && lotId) {

            let totalHours = 0, totalMinutes = 0; 
            let ApproachVal = $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+numField+'][time_approach]"]').val();

            if (ApproachVal) {
                let ApproachValHours    = parseInt(ApproachVal.split(':')[0]);
                let ApproachValMinutes  = parseInt(ApproachVal.split(':')[1]);

                totalHours      = totalHours + ApproachValHours;
                totalMinutes    = totalMinutes + ApproachValMinutes;

                if (totalMinutes >= 60) {
                    totalHours      = totalHours + 1;
                    totalMinutes    = totalMinutes - 60;
                }
            }

            let HandlingVal    = $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+numField+'][time_handling]"]').val();
            if (HandlingVal) {
                let HandlingValHours    = parseInt(HandlingVal.split(':')[0]);
                let HandlingValMinutes  = parseInt(HandlingVal.split(':')[1]);

                totalHours      = totalHours + HandlingValHours;
                totalMinutes    = totalMinutes + HandlingValMinutes;

                if (totalMinutes >= 60) {
                    totalHours      = totalHours + 1;
                    totalMinutes    = totalMinutes - 60;
                }   
            }

            let DrivingVal     = $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+numField+'][time_driving]"]').val();
            if (DrivingVal) {
                let DrivingValHours    = parseInt(DrivingVal.split(':')[0]);
                let DrivingValMinutes  = parseInt(DrivingVal.split(':')[1]);

                totalHours      = totalHours + DrivingValHours;
                totalMinutes    = totalMinutes + DrivingValMinutes;

                if (totalMinutes >= 60) {
                    totalHours      = totalHours + 1;
                    totalMinutes    = totalMinutes - 60;
                } 
            }

            totalHours      = (totalHours.toString().length < 2 || totalHours === 0) ? ('0'+totalHours) : totalHours;
            totalHours      = (totalHours.toString().length < 3 || totalHours === 0) ? ('0'+totalHours) : totalHours;

            totalMinutes    = (totalMinutes.toString().length < 2 || totalMinutes === 0) ? ('0'+totalMinutes) : totalMinutes;

            let totalTime = totalHours+':'+totalMinutes;

            $('input[name="responses['+lotId+']['+energyId+'][drivers_data]['+numField+'][time_total]"]').val(totalTime).attr('value', totalTime);

            $(`#${lotId}_${energyId}_services_time_total_driving`).val('000:00').attr('value', '000:00');

            ResponsePublicMarket.ServicesTimeTotalDriving(lotId, energyId, function(result){
                $(`#${lotId}_${energyId}_services_time_total_driving`).val(result).attr('value', result);
            });
        }
    },

    ServicesTimeTotalDriving : (lotId, energyId, callback) => {

        let totalHours = 0, totalMinutes = 0, count = 0, nbItems = $( "input[name*='[time_total]'][lot-id='"+lotId+"'][energy-id='"+energyId+"']" ).length;

        $( "input[name*='[time_total]'][lot-id='"+lotId+"'][energy-id='"+energyId+"']" ).each(function(index, item){

            let isNumber = index+1;

            count++;

            if (!$(item).parent('.field-row-value-driver[lot-id="'+lotId+'"][energy-id="'+energyId+'"][data-index="'+isNumber+'"]').hasClass('driver-visibility-hidden')) {

                let value       = $(item).val();
                let split       = value.split(':');
                let hours       = parseInt(split[0]);
                let minutes     = split.length > 1 ? parseInt(split[1]) : 0;

                totalHours      = totalHours + hours;
                totalMinutes    = totalMinutes + minutes;

                if (totalMinutes >= 60) {
                    totalHours      = totalHours + 1;
                    totalMinutes    = totalMinutes - 60;
                }
            }

            if (count === nbItems) {

                totalHours      = (totalHours.toString().length < 2 || totalHours === 0) ? ('0'+totalHours) : totalHours;
                totalHours      = (totalHours.toString().length < 3 || totalHours === 0) ? ('0'+totalHours) : totalHours;
                totalMinutes    = (totalMinutes.toString().length < 2 || totalMinutes === 0) ? ('0'+totalMinutes) : totalMinutes;

                return callback(totalHours+':'+totalMinutes)
            }
            
        });
    },

    ServiceCalc : (lotId, energyId) => {

        let totalHours      = 0, 
            totalMinutes    = 0, 
            TimeNightVal    = $(`#${lotId}_${energyId}_service_time_night`).val(),
            TimeDayVal      = $(`#${lotId}_${energyId}_service_time_day`).val();

        if (TimeNightVal) {
            let TimeNightValHours    = parseInt(TimeNightVal.split(':')[0]);
            let TimeNightValMinutes  = parseInt(TimeNightVal.split(':')[1]);

            totalHours      = totalHours + TimeNightValHours;
            totalMinutes    = totalMinutes + TimeNightValMinutes;

            if (totalMinutes >= 60) {
                totalHours      = totalHours + 1;
                totalMinutes    = totalMinutes - 60;
            }
        }

        if (TimeDayVal) {
            let TimeDayValHours    = parseInt(TimeDayVal.split(':')[0]);
            let TimeDayValMinutes  = parseInt(TimeDayVal.split(':')[1]);

            totalHours      = totalHours + TimeDayValHours;
            totalMinutes    = totalMinutes + TimeDayValMinutes;

            if (totalMinutes >= 60) {
                totalHours      = totalHours + 1;
                totalMinutes    = totalMinutes - 60;
            }
        }

        totalHours      = (totalHours.toString().length < 2 || totalHours === 0) ? ('0'+totalHours) : totalHours;
        totalHours      = (totalHours.toString().length < 3 || totalHours === 0) ? ('0'+totalHours) : totalHours;
        totalMinutes    = (totalMinutes.toString().length < 2 || totalMinutes === 0) ? ('0'+totalMinutes) : totalMinutes;

        let totalTime = totalHours+':'+totalMinutes;

        $(`#${lotId}_${energyId}_service_time_total_hundredth`).val(totalTime).attr('value', totalTime);

        // F47
        let F47Val =  TimeDayVal;

        if (F47Val != 0) {
            F47Split = ("" + F47Val).split(':');
            let F47min = (parseInt(F47Split[1]) / 60);
            F47Val = parseFloat(parseInt(F47Split[0])+F47min).toFixed(2);
        }
        $(`#${lotId}_${energyId}_service_time_day_hundredth`).val(F47Val).attr('value', F47Val);

        // F49
        let F49Val =  TimeNightVal;

        if (F49Val != 0) {
            F49Split = ("" + F49Val).split(':');
            let F49min = (parseInt(F49Split[1]) / 60);
            F49Val = parseFloat(parseInt(F49Split[0])+F49min).toFixed(2);
        }
        $(`#${lotId}_${energyId}_service_time_night_hundredth`).val(F49Val).attr('value', F49Val);

        ResponsePublicMarket.RHCalc(lotId, energyId);
        return false;
    },

    RoadCost : (lotId, energyId) => {

        let Common = require('./Common');

        // F59 = (F165 * 14,34) + (F166 * 8,82) + (F167 * 8,59) + (F168 * 7,77) + (F169 * 3,88) + (F170 * 45,84) + (F171 * 60,17) Excel
        // F59 = (F51 * 14,34) + (F52 * 8,82) + (F53 * 8,59) + (F54 * 7,77) + (F55 * 3,88) + (F56 * 45,84) + (F57 * 60,17) Formulaire HTML
        //let $F59 = $('#'+lotId+'_'+energyId+'_total_annual_road_costs');

        let $F51 = $('#'+lotId+'_'+energyId+'_number_meal_allowances');
        let $F52 = $('#'+lotId+'_'+energyId+'_number_of_single_meal_allowances');
        let $F53 = $('#'+lotId+'_'+energyId+'_number_of_single_night_meal_allowances');
        let $F54 = $('#'+lotId+'_'+energyId+'_number_of_breakage_allowances');
        let $F55 = $('#'+lotId+'_'+energyId+'_number_of_special_allowances');
        let $F56 = $('#'+lotId+'_'+energyId+'_one_meal_one_overnight_stay');
        let $F57 = $('#'+lotId+'_'+energyId+'_two_meal_one_overnight_stay');

        // Values
        let F51Val = $F51.val() ? $F51.val() : 0;
            F51Val = ResponsePublicMarket.RebaseNumber(F51Val);
            F51Val = parseInt(F51Val);

        let F52Val = $F52.val() ? $F52.val() : 0;
            F52Val = ResponsePublicMarket.RebaseNumber(F52Val);
            F52Val = parseInt(F52Val);

        let F53Val = $F53.val() ? $F53.val() : 0;
            F53Val = ResponsePublicMarket.RebaseNumber(F53Val);
            F53Val = parseInt(F53Val);

        let F54Val = $F54.val() ? $F54.val() : 0;
            F54Val = ResponsePublicMarket.RebaseNumber(F54Val);
            F54Val = parseInt(F54Val);

        let F55Val = $F55.val() ? $F55.val() : 0;
            F55Val = ResponsePublicMarket.RebaseNumber(F55Val);
            F55Val = parseInt(F55Val);

        let F56Val = $F56.val() ? $F56.val() : 0;
            F56Val = ResponsePublicMarket.RebaseNumber(F56Val);
            F56Val = parseInt(F56Val);

        let F57Val = $F57.val() ? $F57.val() : 0;
            F57Val = ResponsePublicMarket.RebaseNumber(F57Val);
            F57Val = parseInt(F57Val);

        /*let F59Result = (F51Val * 14.34) + (F52Val * 8.82) + (F53Val * 8.59) + (F54Val * 7.77) + (F55Val * 3.88) + (F56Val * 45.84) + (F57Val * 60.17);
            F59Result = F59Result && F59Result != 'Infinity' ? F59Result.toFixed(2) : 0;
            F59Result = Common.numberFormat(F59Result, 2);

        $F59.val(F59Result).attr('value', F59Result);*/

        return false;
    },

    RHCalc : (lotId, energyId) => {

        let Common = require('./Common');

        let $F14 = $('#'+lotId+'_'+energyId+'_human_resources_salaries_and_charges');
        let $F15 = $('#'+lotId+'_'+energyId+'_special_calc_daytime_hourly_rate');//F15 + (F15 * 25%)
        let $F16 = $('#'+lotId+'_'+energyId+'_special_calc_nighttime_hourly_rate');
        let $F47 = $('#'+lotId+'_'+energyId+'_service_time_day_hundredth');
        let $F49 = $('#'+lotId+'_'+energyId+'_service_time_night_hundredth');

        // Values
        let F14Val = $F14.val() ? $F14.val() : 0;
            F14Val = ResponsePublicMarket.RebaseNumber(F14Val);
            F14Val = parseInt(F14Val);

        let F47Val = $F47.val() ? $F47.val() : 0;
            F47Val = ResponsePublicMarket.RebaseNumber(F47Val);
            F47Val = parseFloat(F47Val);

        let F49Val = $F49.val() ? $F49.val() : 0;
            F49Val = ResponsePublicMarket.RebaseNumber(F49Val);
            F49Val = parseFloat(F49Val);

        // Results
        let F15Result = (F14Val/51)/(F47Val+ (F49Val*((125/100)) ));
        let F16Result = F15Result+(F15Result*((25/100)));

        F15Result = F15Result && F15Result != 'Infinity' ? F15Result.toFixed(2) : 0;
        F15Result = Common.numberFormat(F15Result, 2);

        F16Result = F16Result && F16Result != 'Infinity' ? F16Result.toFixed(2) : 0;
        F16Result = Common.numberFormat(F16Result, 2);

        $F15.val(F15Result).attr('value', F15Result);
        $F16.val(F16Result).attr('value', F16Result);

        let F47Value = ("" + F47Val).replace('.', ',');
            F47Value = Common.numberFormat(F47Value, 2);

        let F49Value = ("" + F49Val).replace('.', ',');
            F49Value = Common.numberFormat(F49Value, 2);

        $F47.val(F47Value).attr('value', F47Value);
        $F49.val(F49Value).attr('value', F49Value);

        return false;
    },

    BasicCalcTotal : (name, lotId, energyId) => {

        let Common = require('./Common');

        let $element = $(`#${lotId}_${energyId}_${name}`), total = 0, count = 0, nbItem = $( "input[name*='"+name+"'][lot-id='"+lotId+"'][energy-id='"+energyId+"']" ).length;

        $element.val(total).attr('value', total);

        $( "input[name*='"+name+"'][lot-id='"+lotId+"'][energy-id='"+energyId+"']" ).each(function(index, item){
            if ($(item).attr('name') != name) {

                let val = $(item).val();
                    val = ResponsePublicMarket.RebaseNumber(val);

                total = total + (val.length > 0 ? parseFloat(val) : 0);
            }
            count++;
            if (count === nbItem) {

                total = Common.numberFormat(total, 2);

                $element.val( total ).attr('value', total);

                ResponsePublicMarket.RHCalc(lotId, energyId);
                ResponsePublicMarket.IndirectCost(lotId, energyId);
            }
        });
    },

    ResponseLot : () => {

        $d.off('click', '.response-lot-btn-cancel').on('click', '.response-lot-btn-cancel', function(){
            $('.modal-response-lot').fadeOut(200);
        });

        $d.off('click', '.response-lot-btn').on('click', '.response-lot-btn', function() {
            let lotId = $(this).attr('data-id');
            $('.modal-response-lot[data-id="'+lotId+'"]').fadeIn(200);
        });

        $d.off('click', '.response-lot-message-btn').on('click', '.response-lot-message-btn', function(){

            $('.response-lot-message').hide();
            $(this).parent().parent().children('.response-lot-message').show();
        });

        $d.off('click', '.response-lot-message-close').on('click', '.response-lot-message-close', function(){
            $(this).parent().hide();
        });
    },

    RebaseNumber : (value) => {
        if (value && value.length > 0) {
            value = value.replace(',', '.');
            value = value.split(' ').join('');
        } else {
            value = 0;
        }
        return value;
    },

    NumberInputFormat : ($that) => {

        if (!$that.hasClass('time-cel-number')) {

            let fieldName   = $that.attr('name');
            let lotId       = $that.attr('lot-id');
            let energyId    = $that.attr('energy-id');

            if (fieldName.indexOf('kilometers_cost') != -1) {
                ResponsePublicMarket.BasicCalcTotal('kilometers_cost', lotId, energyId);
            }
            if (fieldName.indexOf('human_resources') != -1) {
                ResponsePublicMarket.BasicCalcTotal('human_resources', lotId, energyId);
            }
            if (fieldName.indexOf('vehicle_subcost') != -1) {
                ResponsePublicMarket.BasicCalcTotal('vehicle_subcost', lotId, energyId);
            }
            if (fieldName.indexOf('indirect_costs') != -1) {
                ResponsePublicMarket.IndirectCost(lotId, energyId);
            }
            if (fieldName.indexOf('number_of_single_meal_allowances') != -1) {
                ResponsePublicMarket.RoadCost(lotId, energyId);
            }
            if (fieldName.indexOf('number_of_single_night_meal_allowances') != -1) {
                ResponsePublicMarket.RoadCost(lotId, energyId);
            }
            if (fieldName.indexOf('number_of_breakage_allowances') != -1) {
                ResponsePublicMarket.RoadCost(lotId, energyId);
            }
            if (fieldName.indexOf('number_of_special_allowances') != -1) {
                ResponsePublicMarket.RoadCost(lotId, energyId);
            }
            if (fieldName.indexOf('number_meal_') != -1) {
                ResponsePublicMarket.RoadCost(lotId, energyId);
            }
            if (fieldName.indexOf('one_meal_') != -1) {
                ResponsePublicMarket.RoadCost(lotId, energyId);
            }
            if (fieldName.indexOf('two_meal_') != -1) {
                ResponsePublicMarket.RoadCost(lotId, energyId);
            }
        }
    }
}
module.exports = ResponsePublicMarket;
