let Router = {
	init: () => {
		let bodyId = document.body.getAttribute('id');

        require('../modules/AdminNavbar').Sidebar();

        if (window.location.href.indexOf('admin') > -1) {
            require('../modules/Accordion').init();
            require('../modules/AdminNavbar').init();
            require('../modules/Loader').init();
            require('../modules/ScrollToTop').init();
            require('../modules/Storage').init();
        } else {
		    require('../modules/Log').init();
            require('../modules/Messenger').init();
            require('../modules/ScrollToTop').init();
        }
        
        switch (bodyId) {
            case 'admin_societies':
                require('../modules/Table').Init('table_societies_list');
                break;
            case 'admin_altares':
                require('../modules/Search').Init('altares_subscription_search', '.list-item', 2);
                break;
            case 'admin_altares_subcontractors':
                require('../modules/Search').Init('altares_subcontractor_search', '.list-item', 2);
                break;
            case 'admin_params':
                require('../modules/AdminParams').init();
                require('../modules/Maintenance').Active();
                break;
            case 'admin_modal':
                require('../modules/Table').Init('table_modals_list');
                require('../modules/AdminModal').ViewModal();
                break;
            case 'admin_modal_edit':
                let modalEditor = require('../modules/FormEditor').init('#modal_content_field');
                require('../modules/AdminModal').init(modalEditor);
                break;
            case 'emails_template':
                require('../modules/AdminEmailTemplate').init();
                require('../modules/Table').Init('table_email_template_list');
                require('../modules/Email').TemplateModalView();
                break;
            case 'emails_template_edit':
                require('../modules/QuillEditor').init('email_edit_quill', 'form_edit_email_template');
                require('../modules/AdminEmailTemplate').init();
                break;
            case 'emails_template_add':
                require('../modules/QuillEditor').init('email_edit_quill', 'form_edit_email_template');
                break;
            case 'admin_society_edit':
                require('../modules/Email').init();
                break;
            case 'andcotransport_home':
                require('../modules/Home.js').init();
                break;
            case 'andcotransport_solutions':
                require('../modules/Solutions.js').init();
                break;
            case 'andcotransport_offers':
                require('../modules/Offers.js').init();
                break;
            case 'appointment_calendar':
                require('../modules/Appointment').init();
                break;
            case 'appointment_form':
                require('../modules/AppointmentForm').init();
            case 'document_management_new':
                require('../modules/DocumentManagementNew.js');
                break;
            case 'document_upload_edit':
                require('../modules/DocumentUploadEdit.js');
                break;
            case 'document_upload_index':
            case 'document_upload_show':
                require('../modules/DocumentUploadValidatefile.js');
                break;
            case 'document_upload_form_pdf':
                require('../modules/DocumentUploadFormPdf.js');
                break;
            case 'document_upload_validate_pdf':
                require('../modules/Pdf.js');
                break;
            case 'map_index':
                require('../modules/Leaflet.Editable');
                require('../modules/Leaflet').init('map', true);
                break;
            case 'offer_new':
            case 'offer_edit':
                require('../modules/OfferMask').init();
                require('../modules/Leaflet.Editable');
                setTimeout(function() { require('../modules/Leaflet').init('map', true); }, 1000);
                break;
            case 'offer_show':
            case 'response_offer_edit':
            case 'response_offer_new':
                require('../modules/Leaflet.Editable');
                require('../modules/Leaflet').init('map');
                break;
            case 'offer_unanswered':
            case 'vigilance_zone_list':
                require('../modules/VigilanceZone');
                break;
            case 'public_market_new':
                require('../modules/PublicMarket').init();
                require('../modules/AjaxUpload').Init();
                break;
            case 'public_market_edit':
                require('../modules/PublicMarket').init();
                require('../modules/Countdown').Init();
                require('../modules/AjaxUpload').Init();
                
                break;
            case 'public_market_list':
                require('../modules/Table').DataTable('public_market_table_list');
                require('../modules/Table').DataTable('public_market_table_list_archive');
                break;
            case 'public_market_params':
                require('../modules/Table').DataTable('public_market_group_users');
                require('../modules/Table').DataTable('public_market_email_wait');
                break;
            case 'registration':
                require('../modules/SocietyApi').init();
                require('../modules/Registration').init();
                break;
            case 'society_show':
                require('../modules/Leaflet').init('map');
                break;
            case 'term_of_use_new':
                require('../modules/QuillEditor').init('quill-editor-toolbar', 'term_of_use');
                break;
            case 'user_privilege':
                require('../modules/UserPrivilege').init();
                break;
            case 'vehicle_income_management_primecontractor':
                require('../modules/DocumentUploadEdit.js');
                break;
            case 'faq':
                require('../modules/Faq.js').Init();
                break;
            case 'response_subcontractor':
                //require('../modules/Leaflet').init('map');
                require('../modules/Countdown').Init();
                require('../modules/ResponsePublicMarket').Init();
                break;
            case 'response_form_subcontractor':
                require('../modules/Countdown').Init();
                require('../modules/ResponsePublicMarket').Init();
                break;
            case 'response_subcontractor_edit':
                //require('../modules/Leaflet').init('map');
                require('../modules/Countdown').Init();
                break;
            case 'response_compare_subcontractor':
                require('../modules/PublicMarketCompare').Init();
                break;
            case 'public_market_forum':
                require('../modules/Forum').Init();
                break;
            case 'public_market_response_form_do':
                require('../modules/Table').DataTable('public_market_response_table_form_do');
                break;
        }
	},
}
module.exports = Router;
