let SSE = {

    /**
     * 
     * @param {string} url 
     * authentification => user_id, user_token
     * connexion active => sse_close = 0
     * fermer la connexion sse => sse_close = 1
     * connexion type => sse_type = 'string' 
     */

    Init : () => {

        /*SSE.Call('/sse/?user_id=1&user_token=2&sse_type=sse_test&sse_close=0', function(result){

            console.log(JSON.parse(result))
        });*/

        /*SSE.Call('/sse/?user_id=1&user_token=2&sse_type=sse_get_messenger_new_message&sse_close=0', function(result){

            if (SSE.IsJsonString(result)) {
                console.log(JSON.parse(result))
            } else {
                console.log(result);
            }
        });*/
    },

    IsJsonString : (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },

    Call : (url, callback) => {

        var eventSource = new EventSource(url);

        eventSource.onmessage = function(event) {

            console.log(event);

            if (event && event.data.length) {
                return callback(event.data);
            }
        };

        eventSource.onopen = function (event) { /*console.log('onopen', event);*/ return callback(event) };
        eventSource.onerror = function (event) { /*console.log('onerror', event);*/ return callback(event) };

    },

    Close : (eventSource) => {

        return eventSource.close();
    }
}
module.exports = SSE;