let Registration = {
    init: () => {
        let pwdInput = document.querySelector('input[name="user[password]"]');
        let pwdInputConf = document.querySelector('input[name="user[password_confirm]"]');

        document.querySelector('input[name="kbis"]').addEventListener('change', function() {
            document.getElementById('form-registration-kbis').submit();
        });

        pwdInput.addEventListener('change', function(event) {
            let isPwdMatch = Registration.checkPassword(pwdInput, pwdInputConf);
            Registration.addBorderPwdInput(isPwdMatch, pwdInput, pwdInputConf);
        });

        pwdInputConf.addEventListener('change', function(event) {
            let isPwdMatch = Registration.checkPassword(pwdInput, pwdInputConf);
            Registration.addBorderPwdInput(isPwdMatch, pwdInput, pwdInputConf);
        });

        document.querySelector('input[name="society[logo]"]').addEventListener('change', function(event) {
            let input = document.getElementById('logo_text_file');
            input.classList.add('text-success');
            input.innerHTML = 'Logo fourni';
        });

        document.querySelector('input[name="society[siret]"]').addEventListener('change', function(event) {
            let siren = document.querySelector('input[name="society[siren]"]').value;
            let siretInput = event.target;
            let fullSiret = siren + siretInput.value;

            if (14 == fullSiret.length) {
                require('./SocietyApi').sireneApi(fullSiret);
            } else {
                siretInput.classList.add('border-danger');
            }
        });

        document.querySelector('input[name="society[intra_community_vat]"]').addEventListener('change', function(event) {
            let vatInput = event.target;

            if (0 < vatInput.value.length) {
                require('./SocietyApi').checkVat(vatInput.value);
            } else {
                vatInput.classList.add('border-danger');
            }
        });

        document.querySelector('select[name="society[address][country]"]').addEventListener('change', function(event) {
            let country = event.target.value;

            if ('FR' == country) {
                $('label[for="licenses_vehicle_true"]').trigger('click');
                $('input[name="attestation"]').prop('required', false);

                $('.license-text-out-fr').hide();
                $('.licenses-title-out-fr').hide();

                $('.licenses-fields').show();
                $('.license-text').show(100);
                $('.licenses-title').show(100);
            } else {
                $('label[for="licenses_vehicle_false"]').trigger('click');
                $('input[name="attestation"]').prop('required', true);

                $('.licenses-fields').hide();
                $('.license-text').hide();
                $('.licenses-title').hide();

                $('.license-text-out-fr').show(100);
                $('.licenses-title-out-fr').show(100);
            }
        });

        document.querySelector('input[name="licenses_vehicle"]').addEventListener('change', function() {
            let value = event.target.value;

            if (value === '1') {
                $('.licenses-container').fadeOut(200);
                $('input[name="attestation"]').prop('required', false)
            } else {
                $('.licenses-container').fadeIn(200);
                $('input[name="attestation"]').prop('required', true)
            }
        });

        document.getElementById('btn-tos-accept').addEventListener('click', function() {
            $('#btn-register').prop('disabled', false);
            $('input[name="cgu_accept"]').prop('disabled', false);
            $('#modal-tos').modal('hide');
        });

        document.getElementById('btn-register').addEventListener('click', function(event) {
            event.preventDefault();

            let isPwdMatch = Registration.checkPassword(pwdInput, pwdInputConf);
            if (false === isPwdMatch) {
                alert('Les mots de passe ne correspondent pas');

                return false;
            }

            let form = document.getElementById('form-registration');
            let formData = new FormData();
            form.elements.forEach(function(input) {
                formData.append(input.name, input.value);
            });

            let xhr = new XMLHttpRequest();
            xhr.open('POST', '/registration');
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.send(formData);

            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    if (200 == xhr.status) {
                        let errors = JSON.parse(xhr.response);
                        let errorString = '';
                        for (error in errors) {
                            errorString+= errors[error] + "\n";
                        }
                        if ('' == errorString) {
                           form.submit();
                        } else {
                            alert(errorString);

                            return false;
                        }
                    }
                }
            };
        });
    },
    checkPassword: (pwdInput, pwdInputConf) => {
        let password = pwdInput.value;
        let passwordConf = pwdInputConf.value;
        if ('' != password && '' != passwordConf && password === passwordConf) {
           return true;
        }

        return false;
    },
    addBorderPwdInput: (isPwdMatch, pwdInput, pwdInputConf) => {
        if (true === isPwdMatch) {
            pwdInput.classList.add('border-success');
            pwdInput.classList.remove('border-danger');
            pwdInputConf.classList.add('border-success');
            pwdInputConf.classList.remove('border-danger');
        } else {
            pwdInput.classList.add('border-danger');
            pwdInput.classList.remove('border-success');
            pwdInputConf.classList.add('border-danger');
            pwdInputConf.classList.remove('border-success');
        }
    },
}
module.exports = Registration;
