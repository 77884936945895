let Maintenance = {
    Active : () => {
        if ($('input[name="params_maintenance_active"]').length) {
            $d.off('change', 'input[name="params_maintenance_active"]').on('change', 'input[name="params_maintenance_active"]', function(){
                let data = {
                    url : $(this).attr('ajax-url'),
                    checked: $(this).prop('checked') ? 1 : 0
                }
                require('./AdminParams').AjaxUpdate(data);
                return;
            });
        }
    }
}
module.exports = Maintenance;