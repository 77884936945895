let AdminModal = {

    init : (Editor) => {
        AdminModal.EditorAction(Editor);
        AdminModal.Actions();
        AdminModal.LoadOnlyOneSocietyDo();
        AdminModal.LoadOnlyOneSocietyStt();
    },

    LoadOnlyOneSocietyDo : () => {

        if ($('input[name="current_only_one_do"]').val().length > 0) {

            let doData = $('input[name="current_only_one_do"]').val();

            if (doData.length > 4) {
                AdminModal.AjaxSearch({keyword : doData, type: 'do', load : true}, function(result){
                    AdminModal.SearchOnlyUser(result, 'do', true);
                });
            }
        }
    },

    LoadOnlyOneSocietyStt : () => {

        if ($('input[name="current_only_one_stt"]').val().length > 0) {

            let sttData = $('input[name="current_only_one_stt"]').val();

            if (sttData.length > 4) {

                AdminModal.AjaxSearch({keyword : sttData, type: 'stt', load : true}, function(result){
                    AdminModal.SearchOnlyUser(result, 'stt', true);
                });
            }
        }

    },

    SearchOnlyUser : (result, type='do', load=false) => {

        if (result && result.response === 'success' && result.data != false) {

            $('.modal-only-'+type+'-results').html('');

            let count = 0;

            if ($('.modal-edit-item[data-type="'+type+'"]').find('.modal-results-count').length < 1) {
                $('<div class="modal-results-count"></div>').insertBefore('.modal-only-'+type+'-results');
            }

            if ($('.modal-edit-item[data-type="'+type+'"]').find('.modal-results-remove').length < 1) {
                $('<div class="modal-results-remove">ANNULER</div>').insertBefore('.modal-only-'+type+'-results');
            }

            if ($('.modal-edit-item[data-type="'+type+'"]').find('.modal-results-select-all').length < 1) {
                $('<div class="modal-results-select-all">tout selectionner</div>').insertBefore('.modal-only-'+type+'-results');
            }

            $.each(result.data, function(index, item){

                $('.modal-only-'+type+'-results').append(`
                    <div class="modal-result-item">
                        <label for="modal_only_${type}_result_${item.society_id+item.user_id}">
                            <div class="item-text-container">
                                <div class="item-title">${item.society_name}</div>
                                <div class="item-text">${item.gender} - ${item.lastname} - ${item.firstname}</div>
                            </div>
                            <div class="item-field-container">
                                <input type="checkbox" id="modal_only_${type}_result_${item.society_id+item.user_id}" name="modal_only_${type}_result[]" value="${item.society_id}-${item.user_id}" ${load ? 'checked' : ''}>
                            </div>
                        </label>
                        ${type === 'do' ? '<div data-id="'+item.society_id+'-'+item.user_id+'" class="item-for-all-stt">Afficher uniquement pour tous les STT de '+item.society_name+'</div>' : ''}
                    </div>
                `);
                count++;

                if (count > 1) {
                    $('.modal-edit-item[data-type="'+type+'"]').find('.modal-results-count').text(count+' resultats');
                } else {
                    $('.modal-edit-item[data-type="'+type+'"]').find('.modal-results-count').text(count+' resultat');
                }
            });
        } else {
            $('.modal-only-'+type+'-results').html('');

            if ($('.modal-edit-item[data-type="'+type+'"]').find('.modal-results-count').length < 1) {
                $('<div class="modal-results-count">0 resultat</div>').insertBefore('.modal-only-'+type+'-results');
            }
        }
    },

    Actions : () => {

        $d.off('click', '.modal-results-select-all').on('click', '.modal-results-select-all', function(){
            $(this).parent().find('input[type="checkbox"]').each(function(index, item){
                $(item).prop('checked', true);
            });
        });

        $d.off('click', '.item-for-all-stt').on('click', '.item-for-all-stt', function(){

            let dataId = $(this).attr('data-id');
            $(this).parent().children('label').children('.item-field-container').children('input[type="checkbox"]').prop('checked', false);

            $('.modal-only-stt-results').parent().children('.modal-results-count').remove();
            $('.modal-only-stt-results').parent().children('.modal-results-remove').remove();
            $('.modal-only-stt-results').parent().children('.modal-results-select-all').remove();

            AdminModal.AjaxLoadSTTDO({data : dataId, sttdo : 1}, function(result){
                AdminModal.SearchOnlyUser(result, 'stt', true);
            });
        });

        $d.off('click', '.js-load-default').on('click', '.js-load-default', function(){

            let type = $(this).parent().parent().parent().attr('data-type');

            console.log(type)

            if (type === 'do') {
                AdminModal.LoadOnlyOneSocietyDo();
            }

            if (type === 'stt') {
                AdminModal.LoadOnlyOneSocietyStt();
            }
        });

        $d.off('click', '.modal-btn-do-search').on('click', '.modal-btn-do-search', function(e){

            e.preventDefault();
            
            let value = $(this).parent().children('input[name="modal_only_do_search"]').val();

            if (value.length > 1) {
                AdminModal.AjaxSearch({keyword : value, type: 'do'}, function(result){
                    AdminModal.SearchOnlyUser(result, 'do');
                });
            }
        });

        $d.off('click', '.modal-btn-stt-search').on('click', '.modal-btn-stt-search', function(e){

            e.preventDefault();
            
            let value = $(this).parent().children('input[name="modal_only_stt_search"]').val();

            if (value.length > 1) {
                AdminModal.AjaxSearch({keyword : value, type: 'stt'}, function(result){
                    AdminModal.SearchOnlyUser(result, 'stt');
                });
            }
        });

        $d.off('click', '.modal-results-remove').on('click', '.modal-results-remove', function(e){
            e.preventDefault();

            let $parent = $(this).parent().parent();
            let type    = $parent.attr('data-type');
            console.log(type);

            $(this).parent().parent('[data-type="'+type+'"]').find('.modal-search-flex').find('input[type="text"]').val('');
            $('.modal-only-'+type+'-results').html('');
            $parent.find('.modal-results-remove').remove();
            $parent.find('.modal-results-count').remove();
            $parent.find('.modal-results-select-all').remove();

            return;
        });

    },

    AjaxLoadSTTDO : (data, callback) => {

        $('.modal-only-stt-results').html('<div class="modal-loader"><i class="fas fa-spinner"></i></div>');

        let Ajax = $.ajax({
            url :'/admin/modal/search/stt/do',
            method: 'POST',
            dataType: 'JSON',
            data : data
        });

        Ajax.done(function(result){
            console.log(result);
            return callback(result);
        });

        Ajax.fail(function(error){
            console.log(error);
            return callback(error);
        });

    },

    AjaxSearch : (data, callback) => {

        $('.modal-only-'+data.type+'-results').html('<div class="modal-loader"><i class="fas fa-spinner"></i></div>');

        let Ajax = $.ajax({
            url :'/admin/modal/search/user',
            method: 'POST',
            dataType: 'JSON',
            data : data
        });

        Ajax.done(function(result){
            console.log(result);
            return callback(result);
        });

        Ajax.fail(function(error){
            console.log(error);
            return callback(error);
        });
    },

    ModalPosition : (modalId) => {
        let $thisModal = $('.modal-modal[data-id="'+modalId+'"]').children('.modal-content');
        let dataWidth = parseInt($thisModal.attr('data-w')), dataHeight = parseInt($thisModal.attr('data-h'));
        let dataX = parseInt($thisModal.attr('data-x')), dataY = parseInt($thisModal.attr('data-y'));
        let width = $thisModal.outerWidth(), height = $thisModal.outerHeight();
        if (dataX === 50) width = width / 2;
        if (dataY === 50) height = height / 2;
        $thisModal.css({
            'width' : dataWidth+'%',
            'height' : dataHeight+'%',
            'left' : 'calc('+dataX+'%  - '+width+'px)',
            'top' : 'calc('+dataY+'% - '+height+'px)'
        });
    },

    ViewModal : () => {

        $d.off('click', '.modal-open-view').on('click', '.modal-open-view', function(e){
            e.preventDefault();
            let modalId = $(this).parent().parent().attr('data-id');
            $('.modal').hide();
            $('.modal-modal[data-id="'+modalId+'"]').show();
            AdminModal.ModalPosition(modalId);            
            return;
        });
    },

    EditorAction : (Editor) => {

        Editor.on('text-change',() => {
            $('textarea[name=modal_content]').val(Editor.container.firstChild.innerHTML)
        });
    }
}
module.exports = AdminModal;