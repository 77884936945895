import 'bootstrap';
import bsCustomFileInput from 'bs-custom-file-input';
import 'bootstrap-table';
import fixedColumns from 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns';
import '../scss/app.scss';
import jQuery from 'jquery';

'use-strict';

window.$  = jQuery.noConflict();
window.$d = $(document);

window.addEventListener('load', function () {
    if ($('#global_loader').length) $('#global_loader').addClass('global-loader-hidden');
});

bsCustomFileInput.init();

jQuery(function () {
    jQuery('[data-toggle="tooltip"]').tooltip()
})

if (jQuery('#flash-notice-modal')) {
    jQuery('#flash-notice-modal').modal('show');
}

$d.ready(function() {

    require('./modules/Common').init();
    require('./modules/Modal').Init();
    require('./modules/Locale').init();
    require('./modules/ModSw').init();
    require('./modules/Prompt').init();
    require('./modules/Tooltip').Init();
    require('./router/Router').init();
    require('./modules/SSE').Init();
})


