let SocietyApi = {
    init : () => {},

    sireneApi : (siret) => {
        SocietyApi.getData('siret', siret);
    },

    checkVat : (vat) => {
        SocietyApi.getData('intra_community_vat', vat);
    },

    getData: (field, value) => {
        let formData = new FormData();
        formData.append(field, value);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/society/api');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send(formData);

        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                let input =  document.querySelector('input[name="society[' + field + ']"]');
                input.classList.remove('border-success');
                input.classList.remove('border-danger');
                if (200 == xhr.status && 'false' != xhr.response) {
                    input.classList.add('border-success');
                    input.readOnly = true;
                    SocietyApi.fillForm(xhr.response, field);
                } else {
                    input.classList.add('border-danger');
                }
            }
        }
    },

    fillForm: (json) => {
        let data = JSON.parse(json);
        var values = {};
        if (data.etablissement) {
            if (data.etablissement.unite_legale.numero_tva_intra) {
                values['society[intra_community_vat]'] = data.etablissement.unite_legale.numero_tva_intra;
            }
            if (data.etablissement.date_creation) {
                values['society[company_creation_date]'] = data.etablissement.date_creation;
            }
            if (data.etablissement.numero_voie) {
                values['society[address][street_number]'] = data.etablissement.numero_voie;
            }
            if (data.etablissement.type_voie) {
                values['society[address][street_type]'] = data.etablissement.type_voie;
            }
            if (data.etablissement.libelle_voie) {
                values['society[address][street_name]'] = data.etablissement.libelle_voie;
            }
            if (data.etablissement.complement_adresse) {
                values['society[address][additional_address]'] = data.etablissement.complement_adresse;
            }
            if (data.etablissement.code_postal) {
                values['society[address][postal_code]'] = data.etablissement.code_postal;
            }
            if (data.etablissement.libelle_commune) {
                values['society[address][city]'] = data.etablissement.libelle_commune;
            }
            if (data.etablissement.unite_legale.sexe) {
                values['user[person][gender]'] = data.etablissement.unite_legale.sexe;
            }
            if (data.etablissement.unite_legale.nom_usage) {
                values['user[person][last_name]'] = data.etablissement.unite_legale.nom_usage;
            }
            if (data.etablissement.unite_legale.nom) {
                values['user[person][last_name]'] = data.etablissement.unite_legale.nom;
            }
            if (data.etablissement.unite_legale.prenom_usuel) {
                values['user[person][first_name]'] = data.etablissement.unit_legale.prenom_usuel;
            }
            if (data.etablissement.unite_legale.prenom_1) {
                values['user[person][first_name]'] = data.etablissement.unit_legale.prenom_1;
            }
        }
        // checkVat
        if (data.countryCode) {
            values['society[address][country]'] = data.countryCode;
        }
        Object.keys(values).forEach(function (inputName) {
            let elem = document.querySelector('[name="' + inputName + '"]');
            let value = values[inputName];
            elem.value = value;
            elem.readOnly = true;
        });
    },
}
module.exports = SocietyApi;
