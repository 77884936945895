require('leaflet');
let Leaflet = {
    marker : null,
    init: (mapId, addEventListener = false) => {

        console.log('** MAP Load ***')

        window['map'] = L.map(mapId, {
            editable: true,
            scrollWheelZoom: false,
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(window.map);

        Leaflet.marker = L.icon({
            iconUrl: '/img/marker-icon.png',
            iconSize: [25, 41],
            iconAnchor: [12, 20]
        });

        if (true == addEventListener) {
            Leaflet.addEventListener();
        } else {
            Leaflet.loadMaps();
        }
    },

    addEventListener: () => {

        $('#map').hide();

        document.querySelector('button.newMap').addEventListener('click', Leaflet.newMap);

        let elements = document.getElementsByClassName('loadMap');

        Array.from(elements).forEach(function(element) {
            element.addEventListener('click', Leaflet.loadMap);
        });
    },

    newMap: () => {

        $('#map').show();

        document.querySelector('input[name="map[id]"]').value = '0';

        let address = document.querySelector('input[name="map[address]"]').value;

        if (undefined != address && '' != address) {

            let request = new Request(location.protocol + '//nominatim.openstreetmap.org/search?format=json&q=' + address);

            fetch(request)
                .then(response => response.json())
                .then(coordinates => {
                    if (coordinates) {
                        let latlng = L.latLng(coordinates[0]['lat'], coordinates[0]['lon']);
                        let bounds = Leaflet.getCircleBounds(latlng);
                        Leaflet.drawMap(bounds);
                    }
                });
        } else {
            alert('Veuillez saisir un secteur');
        }
    },

    loadMap: (event) => {

        $('#map').show();

        let json = JSON.parse(event.target.value);
        document.querySelector('input[name="map[id]"]').value = json.id
        document.querySelector('input[name="map[address]"]').value = json.address;
        let bounds = json.bounds;
        Leaflet.drawMap(bounds);
    },

    drawMap: (bounds) => {
        if (undefined != window.polygon) {
            window.polygon.removeFrom(window.map);
        }

        window.map.on('editable:editing', function() {
            Leaflet.setBoundsToForm();
        });

        Leaflet.drawPolygon(bounds);
        Leaflet.setBoundsToForm();
        let submit = document.getElementById('saveMap');
        if (null != submit) {
            submit.classList.remove('d-none');
        }
    },

    drawPolygon: (bounds) => {
        let latlng = L.latLng(bounds[0], bounds[1]);
        window.map.setView(latlng, 11);

        window['polygon'] = L.polygon(bounds).addTo(window.map);
        window.polygon.enableEdit();
        window.map.fitBounds(window.polygon.getBounds());
    },

    // https://stackoverflow.com/questions/14664685/google-maps-circle-to-polyline-coordinate-array
    getCircleBounds: (point, radius = 5, dir = 1) => {
        var d2r = Math.PI / 180; // degrees to radians
        var r2d = 180 / Math.PI; // radians to degrees
        var earthsradius = 3963; // 3963 is the radius of the earth in miles
        var points = 10;

        // find the radius in lat/lon
        var rlat = (radius / earthsradius) * r2d;
        var rlng = rlat / Math.cos(point.lat * d2r);

        var bounds = [];
        if (dir==1) {
            var start=0;
            var end=points+1; // one extra here makes sure we connect the path
        } else {
            var start=points+1;
            var end=0;
        }
        for (var i=start; (dir==1 ? i < end : i > end); i=i+dir) {
            var theta = Math.PI * (i / (points/2));
            ey = point.lng + (rlng * Math.cos(theta)); // center a + radius x * cos(theta)
            ex = point.lat + (rlat * Math.sin(theta)); // center b + radius y * sin(theta)
            bounds.push([ex, ey]);
        }

        return bounds;
    },

    setBoundsToForm: () => {
        let latlngs = window.polygon._latlngs;
        let bounds = [];
        latlngs[0].forEach(function(latlng) {
            bounds.push(latlng.lat);
            bounds.push(latlng.lng);
        });
        document.querySelector('textarea[name="map[map_coordinates]"]').value = bounds.join(',');
    },

    loadMaps: () => {
        let elements = document.querySelectorAll('input[name="map[]"]');
        if (0 < elements.length) {
            let fitBounds = null;
            elements.forEach(function(element) {
                let json = JSON.parse(element.value);

                console.log(element);

                let bounds = json.bounds;
                window['polygon'] = L.polygon(bounds).addTo(window.map);
                let extendBounds = window.polygon.getBounds();
                if (null != fitBounds) {
                    fitBounds.extend(extendBounds);
                } else {
                    fitBounds = extendBounds;
                }
            });
            map.fitBounds(fitBounds);
        } else {

            console.log('*** LOAD SIMPLE MAP ***')

            window.map.setView([43.296482, 5.36978], 13);

            //L.marker([51.5, -0.09], {icon:Leaflet.marker}).addTo(window.map).bindPopup('')
            //.openPopup();
        }

    },
}
module.exports = Leaflet;
