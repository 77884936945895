let storage = require('./Storage')
let Table = {
    Init : ( tableId, paginate=false ) => {
        Table.ReInit();
        Table.ExpandTable();
        Table.SelectItems(tableId);
        Table.SortTable(tableId);
        Table.Resizable(tableId);

        if (paginate) {
            Table.Paginate(tableId);
            Table.AddSort(tableId)
        }
    },

    AddSort : (tableId) => {

        if ($('#'+tableId).length) {

            let $table = $('#'+tableId);
            //let trLength = $table.children('tbody > tr').length;

            $table.find('th').each(function(index, item) {
                
                $(item)
                    .attr('data-index', index)
                    .css({
                        'position' : 'relative',
                        'overflow': 'hidden'
                    })
                    .append(`
                        <div style="position:absolute;top:0;right:0;font-size:11px;padding:5px;">
                            <div>
                                <div><i class="fas fa-angle-up"></i></div>
                                </div><i class="fas fa-angle-down"></i></div>
                            </div>
                        </div>
                    `)
            });

            $('.td-balancesheet').each(function(index, item){
                $(item).css('text-align', 'right');
                if ($(item).attr('title').length) { 
                    let format = (parseFloat($(item).attr('title')).toLocaleString(undefined, { minimumFractionDigits: 0 }));
                    $(item).text( (format ? format+' €' : '0 €') );
                }
            });

            $d.off('click', '.fa-angle-up').on('click', '.fa-angle-up', function(){
                Table.SortTableAction(tableId, $(this).closest('th').index(), 'asc');
                $(this)
            })

            $d.off('click', '.fa-angle-down').on('click', '.fa-angle-down', function(){
                Table.SortTableAction(tableId, $(this).closest('th').index(), 'desc');
                $(this)
            })
        }

        //<div><div><i class="fas fa-angle-up"></i></div></div><i class="fas fa-angle-down"></i></div></div>
    },

    Actions : () => {

    },

    DataTableDefaultParams : {
        "language": {
            "sUrl": '/datatable/lang/default',
        },
        "aLengthMenu": [ [10, 25, 50, 100, 1000, -1], [10, 25, 50, 100, 1000, 'Tous'] ],
    },

    DataTable : (tableId, params=false) => {

        if  ($('#'+tableId).find('tbody > tr').length >= 2) {
            
            require('datatables.net-bs4');
            require('datatables.net-buttons-bs4');
            require('datatables.net-select-bs4');

            let table = null;
            if (!$.fn.dataTable.isDataTable('#'+tableId) ) {
                table = $('#'+tableId).DataTable((params ? params : Table.DataTableDefaultParams))

                $('.td-balancesheet').each(function(index, item){
                    $(item).css('text-align', 'right');
                    if ($(item).attr('title').length) { 
                        let format = (parseFloat($(item).attr('title')).toLocaleString(undefined, { minimumFractionDigits: 0 }));
                        $(item).text( (format ? format+' €' : '0 €') );
                    }
                });
            }

            if (tableId != 'public_market_response_form_do') {
                require('./PublicMarket.js').SelectAllSubcontractors();
            }

            return table;
        }
    },

    ReInit : () => {

        if ($('.refresh-option').length) {
            $d.off('click', '.refresh-option').on('click', '.refresh-option', function(e){
                e.preventDefault();
                window.location = window.location.href;
                return;
            });
        }
    },

    SelectItems : (tableId) => {

        if ($('input[name="table_select_all"]').length) {

            Table.CleanSelectedItems();

            $d.off('change', 'input[name="table_select_all"]').on('change', 'input[name="table_select_all"]', function(){

                let state = $(this).prop('checked');

                $('input[name="table_select_item"]').each(function(index, item){

                    let id = $(item).closest('tr').attr('data-id')

                    if (state) {
                        $(item).prop('checked', true);
                        //$('form[data-action="delete-table-items"]').append('<input type="hidden" name="table_items_selected[]" value="'+id+'" />');
                        $('form#form_'+tableId).append('<input type="hidden" name="table_items_selected[]" value="'+id+'" />');
                    } else {
                        $(item).prop('checked', false);
                        $('input[name="table_items_selected[]"][value="'+id+'"]').remove();
                    }
                });
            });

            $d.off('change', 'input[name="table_select_item"]').on('change', 'input[name="table_select_item"]', function(){

                let id = $(this).closest('tr').attr('data-id')

                if ($(this).prop('checked')) {
                    $('form[data-action="delete-table-items"]').append('<input type="hidden" name="table_items_selected[]" value="'+id+'" />');
                } else {
                    $('input[name="table_items_selected[]"][value="'+id+'"]').remove();
                }
            });

            $d.off('click', '.delete-selected-option').on('click', '.delete-selected-option', function(e){
                e.preventDefault();
                if ($('input[name="table_items_selected[]"]').length) {
                    $('.modal-confirm').fadeIn(300);

                    $d.off('click', '.modal-btn-confirm').on('click', '.modal-btn-confirm', function(){
                        $('.modal-btn-cancel').trigger('click');
                        $('form[data-action="delete-table-items"]').submit();
                    });
                }
            });
        }
    },

    CleanSelectedItems : () => {

        $('input[name="table_select_all"]').prop('checked', false);
        $('input[name="table_select_item"]').each(function(index, item){
            $(item).prop('checked', false);
        });
    },

    ExpandTable : () => {

        if (storage.TableExpandedGet() === 'true') {
            if ($('.expand-option').length) {
                $('.expand-option').each(function(index, item){
                    Table.ExpandTableAction($(item));
                })
            }
        }

        $d.off('click', '.expand-option').on('click', '.expand-option', function(e) {
            e.preventDefault();
            Table.ExpandTableAction($(this));
        });
    },

    Resizable : (tableId) => {

        require('jquery-ui-dist/jquery-ui');

        var thHeight = $('#'+tableId+' th:first').height();

        $('#'+tableId+' th').resizable({
            handles: "e",
            minHeight: thHeight,
            maxHeight: thHeight,
            minWidth: 40,
            resize: function (event, ui) {
                var sizerID = "#" + $(event.target).attr("id") + "-sizer";
                $(sizerID).width(ui.size.width);
            }
        });

    },

    ExpandTableAction : ($this) => {

        if ($this.parent('.table-options').parent().find('.table-container').hasClass('table-expanded')) {
            $this.parent('.table-options').parent().find('.table-container').removeClass('table-expanded');
            $this.removeClass('expand-option-active');
            storage.TableExpandedSet('false');
        } else {
            $this.parent('.table-options').parent().find('.table-container').addClass('table-expanded');
            $this.addClass('expand-option-active');
            storage.TableExpandedSet('true');
        }
    },

    SortTable : (tableId) => {

        $d.off('click', '.sort-col').on('click', '.sort-col', function( e ){
            e.preventDefault();

            if ($('.sort-col-selected').attr('data-dir') != $(this).attr('data-dir') || $('.sort-col-selected').closest('th').index() != $(this).closest('th').index()) {
                $('#admin_loader').show();
                $('.sort-col').removeClass('sort-col-selected');
                let $this = $(this);
                $this.addClass('sort-col-selected');
                setTimeout(function(){
                    Table.SortTableAction(tableId, $this.closest('th').index(), $this.attr('data-dir'));
                }, 500);
            }
        });
    },

    SortTableAction : (tableId, index, dir) => {

        let $table = $('#'+tableId);

        $table.children('tbody').children('tr').show();

        dir = dir.toLowerCase();
        var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
        table = document.getElementById(tableId);
        switching = true;

        while (switching) {
            switching = false;
            rows = table.rows;

            for (i = 1; i < (rows.length - 1); i++) {
                shouldSwitch = false;
                x = rows[i].getElementsByTagName("TD")[index];
                y = rows[i + 1].getElementsByTagName("TD")[index];

                if (isNaN(parseInt(x.innerHTML.toLowerCase())) && isNaN(parseInt(y.innerHTML.toLowerCase()))) {
                    if (dir === "asc") {
                        if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
                            shouldSwitch = true;
                            break;
                        }
                    } else if (dir === "desc") {
                        if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
                            shouldSwitch = true;
                            break;
                        }
                    }
                } else {
                    if (dir === "asc") {
                        if (parseInt((x.innerHTML.toLowerCase()).replace('€', '')) > parseInt((y.innerHTML.toLowerCase()).replace('€', ''))) {
                            shouldSwitch = true;
                            break;
                        }
                    } else if (dir === "desc") {
                        if (parseInt((x.innerHTML.toLowerCase()).replace('€', '')) < parseInt((y.innerHTML.toLowerCase()).replace('€', ''))) {
                            shouldSwitch = true;
                            break;
                        }
                    }
                }

                
            }

            if (shouldSwitch) {
                rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
                switching = true;
                switchcount ++;
            } else {
                if (switchcount == 0 && dir == "asc") {
                    dir = "desc";
                    switching = true;
                }
            }
            $('#admin_loader').fadeOut(300);
        }

        Table.Paginate(tableId);
    },

    Paginate : (tableId, itemVisible=false) => {

        if ($('#'+tableId).length > 0) {

            let $table      = $('#'+tableId);
            let rowVisible  = itemVisible ? itemVisible : 15;
            let trLength    = $table.children('tbody').children('tr').length;

            let numberPage = trLength / rowVisible;
                numberPage = Math.ceil(numberPage);

            $table.children('tbody').children('tr').hide();

            $table.children('tbody').children('tr').each(function(index,item){
                if ((index+1) <= rowVisible) $(item).show();
            });

            if ($('#'+tableId+'_pagination').length) {
                $('#'+tableId+'_pagination').remove();
            }

            $('<ul id="'+tableId+'_pagination" class="jquery-pagination"></ul>').insertAfter($table);

            for (var i = 1; i <= numberPage; i++) {
                let currentClass = (i === 1 ? 'jquery-pagination-page-current' : '');
                $('#'+tableId+'_pagination').append('<li item-min="'+((rowVisible * i) - rowVisible)+'" item-max="'+rowVisible * i+'" class="jquery-pagination-page '+currentClass+'">'+i+'</li>');
            }

            $d.off('click', '.jquery-pagination-page, .jquery-pagination-page-current').on('click', '.jquery-pagination-page, .jquery-pagination-page-current', function(){

                $('.jquery-pagination-page').removeClass('jquery-pagination-page-current');
                $(this).addClass('jquery-pagination-page-current');

                let min = parseInt($(this).attr('item-min'));
                let max = parseInt($(this).attr('item-max'));

                $table.children('tbody').children('tr').hide();

                $table.children('tbody').children('tr').each(function(index, item){
                    if ((index+1) > min && (index+1) <= max) $(item).show();
                });
            });

            $('.jquery-pagination-page').first().trigger('click');
        }
    }
}
module.exports = Table;
