var elements = document.getElementsByClassName('ajax-upload');
Array.from(elements).forEach(function(element) {
    element.addEventListener('change', upload);
});

function upload(event)
{
    var inputFile = this;
    var progressBar = document.getElementById('progress-bar' + this.name);
    updateProgressBar(progressBar, 'bg-info', 25, 'Uploading file ...');

    var formData = new FormData();
    formData.append('file', inputFile.files[0]);
    formData.append('identifier', inputFile.name);

    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/document/upload/upload');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(formData);

    xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
            var percent = parseFloat((event.loaded / event.total) * 100);
            updateProgressBar(progressBar, percent, 'Uploading file ...');
        }
    }
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
            let status = xhr.response;
            switch (xhr.status) {
                case 201 :
                    switch (status) {
                        case 'ok':
                            tdClass = 'bg-success-v3';
                            tdStatus = 'Fichier validé';
                            break;
                        case 'refused':
                            tdClass = 'bg-danger-v3';
                            tdStatus = 'Fichier refusé';
                            break;
                        default:
                            tdClass = 'bg-info-v3';
                            tdStatus = 'Attente validation';
                            break;
                    }
                    bgClass = 'bg-success';
                    status = 'Fichier téléchargé';
                    inputFile.remove();

                    let td = document.getElementById('td-' + inputFile.name);
                    td.className = tdClass + ' font-weight-bold';
                    td.innerHTML = tdStatus;
                    break;
                case 200 :
                    bgClass = 'bg-warning';
                    break;
                default :
                    bgClass = 'bg-danger';
                    break;
            }
            updateProgressBar(progressBar, bgClass, 100, status);
        }
    }
}

function updateProgressBar(progressBar, bgClass, percent, text = null)
{
    progressBar.classList.remove(
        'bg-info',
        'bg-success',
        'bg-warning',
        'bg-danger'
    );
    progressBar.classList.add(bgClass);
    progressBar.setAttribute('style', 'width: ' + percent + '%');
    progressBar.setAttribute('aria-valuenow', percent);
    if (null != text) {
        progressBar.textContent = text;
    }
}
