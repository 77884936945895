let QuillEditor = {
    init: (editorId, formId) => {
        let Quill = require('quill');
            Quill = QuillEditor.InlineStyle(Quill);

        let Editor  = new Quill('#' + editorId, QuillEditor.Options(Quill));

        let form = document.getElementById(formId);
        form.addEventListener('submit', function(event) {
            // check without tags
            let value = document.querySelector('.ql-editor').textContent;
            if (undefined == value || '' == value) {
                event.preventDefault();
                alert('Veuillez saisir un texte');

                return false;
            }
            value = document.querySelector('.ql-editor').innerHTML;
            document.querySelector('textarea.quill-editor').value = value;
        });
    },

    InlineStyle: (Quill) => {
        var DirectionAttribute = Quill.import('attributors/attribute/direction');
        Quill.register(DirectionAttribute,true);

        var AlignClass = Quill.import('attributors/class/align');
        Quill.register(AlignClass,true);

        var BackgroundClass = Quill.import('attributors/class/background');
        Quill.register(BackgroundClass,true);

        var ColorClass = Quill.import('attributors/class/color');
        Quill.register(ColorClass,true);

        var DirectionClass = Quill.import('attributors/class/direction');
        Quill.register(DirectionClass,true);

        var FontClass = Quill.import('attributors/class/font');
        Quill.register(FontClass,true);

        var SizeClass = Quill.import('attributors/class/size');
        Quill.register(SizeClass,true);

        var AlignStyle = Quill.import('attributors/style/align');
        Quill.register(AlignStyle,true);

        var BackgroundStyle = Quill.import('attributors/style/background');
        Quill.register(BackgroundStyle,true);

        var ColorStyle = Quill.import('attributors/style/color');
        Quill.register(ColorStyle,true);

        var DirectionStyle = Quill.import('attributors/style/direction');
        Quill.register(DirectionStyle,true);

        var FontStyle = Quill.import('attributors/style/font');
        Quill.register(FontStyle,true);

        var SizeStyle = Quill.import('attributors/style/size');
        Quill.register(SizeStyle,true);

        return Quill;
    },

    Options: (Quill) => {
        return {
            debug: false,
            modules: {
                'syntax': false,
                'toolbar': QuillEditor.Toolbar(Quill)
            },
            placeholder: 'Rédaction & mise en forme',
            readOnly: false,
            theme: 'snow'
        }

    },

    Toolbar: (Quill) => {
        var Size = Quill.import('attributors/style/size');
        Size.whitelist = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '30px', '34px'];
        Quill.register(Size, true);

        return [
            [{ 'size': Size.whitelist }],
            [ 'bold', 'italic', 'underline', 'strike' ],
            [{ 'align': [] }],
            [{ 'color': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet'}],
            [ 'link', 'image']
        ]
    },
}
module.exports = QuillEditor;
