let Home = {

    init : () => {
        Home.BannerSlider();
    },

    BannerSlider : () => {

        require('slick-carousel');

        $('.header-image-slider').slick({
            dots: true,
            dotsClass : 'home-header-slick-dots',
            infinite: true,
            customPaging: function(slider, i) { 
                console.log(slider.$slides[i])
                return '<button class="tab">' + $(slider.$slides[i]).find('.image-slider').attr('title') + '</button>';
            },
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800,
        });


        $('.mac-middle-slider-content').slick({
            dots: true,
            appendDots : $('.middle-slider-items'),
            dotsClass : 'mac-middle-slick-dots',
            infinite: true,
            customPaging: function(slider, i) {
                return '<button class="tab '+$(slider.$slides[i]).find('.slide-content').attr('custom-class')+'">' + $(slider.$slides[i]).find('.slide-content').attr('title') + '</button>';
            },
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800,
        });
    }
}
module.exports = Home;
