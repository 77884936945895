let Tooltip = {

    Init : () => {

        if ($('.as-tooltip').length) {
            $d.off('mouseenter', '.as-tooltip').on('mouseenter', '.as-tooltip', function( e ){

                if ($('.tooltip').length) $('.tooltip').remove();

                if ($(this).attr('data-tooltip') != undefined && $(this).attr('data-tooltip') != '') {

                    $('body').append('<span class="tooltip">'+$(this).attr('data-tooltip')+'</span>');

                    if (!$(this).attr('tooltip-left') && !$(this).attr('tooltip-right')) {
                        $('.tooltip').css({'top' : Tooltip.Top( e )+'px', 'left' : Tooltip.Left( e )+'px'});
                    } else if ($(this).attr('tooltip-left')) {
                        $('.tooltip').css({'top' : (Tooltip.Top( e )-30)+'px', 'left' : (Tooltip.Left( e )+parseInt($(this).attr('tooltip-left')))+'px'});
                    } else if ($(this).attr('tooltip-right')) {
                        $('.tooltip').css({'top' : (Tooltip.Top( e )-30)+'px', 'left' : (Tooltip.Left( e )-parseInt($(this).attr('tooltip-right')))+'px'});
                    } 
                }
            });
            $d.off('mouseleave', '.as-tooltip').on('mouseleave', '.as-tooltip', function( e ){ 
                $('.tooltip').remove(); 
            });
        }
    },
    Top : ( e ) => {
        return e.clientY + 30;
    },
    Left : ( e ) => {
        let tooltipW = $('.tooltip').width();
        if (tooltipW > e.clientX) return 5;
        return e.clientX - ($('.tooltip').width()/2);
    }
}
module.exports = Tooltip;