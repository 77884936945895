let AppointmentForm = {

    init : () => {
        console.log('*** APPOITMENT FORM ***');
        //AppointmentForm.Load();
        //AppointmentForm.Actions();
    },

    Load : () => {
        if ($('select[name="appointment_society"]').length) {
            let societyId = $('select[name="appointment_society"]').val();
            AppointmentForm.LoadAddress(societyId);
            AppointmentForm.LoadVehicule(societyId);
        }
    },

    Actions : () => {
        $d.off('change', '#appointment_society').on('change', '#appointment_society', function(){
            console.log('*** CHANGE ***')
            AppointmentForm.LoadAddress($(this).val());
            AppointmentForm.LoadVehicule($(this).val());
        });
    },

    LoadAddress : (societyId) => {
        $('#appointment_address_container').html('<i class="fas fa-spinner"></i>');

        let Ajax = $.ajax({
            url: '/appointment/ajax/address',
            method: 'POST',
            dataType: 'HTML',
            data : {
                society_id : societyId
            }
        });

        Ajax.done(function(html){
            if (html) {
                $('#appointment_address_container').html(html);
            } else {
                $('#appointment_address_container').html('');
            }
        });

        Ajax.fail(function(error){
            console.log(error)
        });
    },

    LoadVehicule : (societyId) => {
        $('#appointment_vehicle_container').html('<i class="fas fa-spinner"></i>');

        let Ajax = $.ajax({
            url: '/appointment/ajax/vehicles',
            method: 'POST',
            dataType: 'HTML',
            data : {
                society_id : societyId
            }
        });

        Ajax.done(function(html){
            if (html) {
                $('#appointment_vehicle_container').html(html);
            } else {
                $('#appointment_vehicle_container').html('');
            }
        });

        Ajax.fail(function(error){
            console.log(error)
        });
    }
}
module.exports = AppointmentForm;