var elements = document.getElementsByClassName('delete-tr');
Array.from(elements).forEach(function(element) {
    element.addEventListener('click', deleteRow);
});

function deleteRow(event)
{
    var tr = this.parentElement.parentElement;
    tr.remove();
}

/*
jQuery(document).ready(function() {
switch ({{ document_model.pdfCreation}}) {
    case 'flotteVehicules' :
    case 'salariesEtrangers' :
        if ($data['creation_pdf'] == 'flotteVehicules') {

    jQuery('div#{{ document_model.pdfCreation }}_choix').addClass('hidden');
    jQuery('div#{{ document_model.pdfCreation }}_import').removeClass('hidden');

        } else if ($data['creation_pdf'] == 'salariesEtrangers') {

    jQuery('div#creation_pdf_submit').addClass('hidden');
    jQuery('a.{{ document_model.pdfCreation }}_choix').click(function() {
        var title = jQuery(this).attr('title');
        if (title) {
            title+= "\r\n\r\n";
        }
        ret = confirm(title + 'Êtes-vous sûr ?');
        if (ret) {
            var choix = jQuery(this).text().trim();
            view_choix(choix);
        }
    });

            if (isset($data['liste_salariesEtrangers'])) {

    var choix = ' echo $data['liste_salariesEtrangers'] ? 'Oui' : 'Non'; ';
    view_choix(choix);

            }

    function view_choix(choix) {
        var val = 1;
        if (choix == 'Non') {
            val = 0;
            // supprime les dates requises car le tableau est masqué
            jQuery('table#salariesEtrangers input').removeAttr('min');
        } else if (choix == 'Oui') {
            jQuery('div#{{ document_model.pdfCreation }}_import').removeClass('hidden');
        }
        // vérification tableaux envoyés
        jQuery('input[name=liste_salariesEtrangers]').val(val);

        // force les valeurs pour remplir le 2e tableau
            if ($data['fonction_pdf'] == 'salariesActivite') {

        val = 1;
        jQuery('div#{{ document_model.pdfCreation }}_import').removeClass('hidden');
        if (choix == 'Non') {
            jQuery('table#salariesEtrangers').next('button.add-line').remove();
            jQuery('table#salariesEtrangers').remove();
        }

            }

        jQuery('div#{{ document_model.pdfCreation }}_choix').addClass('hidden');
        jQuery('input[name=data_pdf]').val(val);
        jQuery('div#creation_pdf_submit').removeClass('hidden');
    }

        }

    jQuery('input[name={{ document_model.pdfCreation }}_import]').change(function() {
        jQuery('#{{ document_model.pdfCreation }}_import_fichier, #{{ document_model.pdfCreation }}_import_manuel').addClass('hidden');
        var import_type = jQuery(this).val();
        if (import_type == 'fichier') {
            jQuery('table#{{ document_model.pdfCreation }} input').attr('disabled', true);
        } else if (import_type == 'manuel') {
            jQuery('table#{{ document_model.pdfCreation }} input').attr('disabled', false);
        }
        jQuery('#{{ document_model.pdfCreation }}_import_' + import_type).removeClass('hidden');
    });
    // gestion tableau import_manuel
    jQuery('button.add-line').click(function(e) {
        e.preventDefault(); // prevent submit
        var table = jQuery(this).prev('table');
        jQuery(table).find('tbody tr.hidden').clone().toggleClass().appendTo('#' + table.attr('id') + ' tbody');
    });

        break;
    case 'protocoleSecurite' :

    var dwl_class = 'downloaded_{{ document_model.pdfCreation }}';
    jQuery('div#creation_pdf_submit').addClass('invisible');
    jQuery('a.download_protocoleSecurite').click(function(e) {
        jQuery(this).addClass(dwl_class);
        jQuery(this).after('<span class="glyphicon glyphicon-ok"></span>');
        var nb_dwl = 0;
        jQuery(this).parent('li').parent('ul').find('li a.download_protocoleSecurite').each(function() {
            if (jQuery(this).hasClass(dwl_class)) {
                ++nb_dwl;
            }
        });
        if (nb_dwl ==  echo count($gabarits); ) {
            jQuery('div#creation_pdf_submit').removeClass('invisible');
        }
    });


        break;
}

});
*/
