let Storage = {

    init : () => {
        if (window.localStorage) {
            if(!window.localStorage.getItem('sidebar_is_open')) {
                window.localStorage.setItem('sidebar_is_open','false');
            }
            if(!window.localStorage.getItem('table_expanded')) {
                window.localStorage.setItem('table_expanded','false');
            }
        }
    },

    TableExpandedGet : () => {
        if (window.localStorage) {
            return window.localStorage.getItem('table_expanded')
        }
    },

    TableExpandedSet : (value) => {
        if (window.localStorage) {
            window.localStorage.setItem('table_expanded', value);
        }
    },

    SidebarOpenGet : () => {
        if (window.localStorage) {
            return window.localStorage.getItem('sidebar_is_open')
        }
    },

    SidebarIsOpenSet : (value) => {
        if (window.localStorage) {
            window.localStorage.setItem('sidebar_is_open', value);
        }
    }
}
module.exports = Storage;