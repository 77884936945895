let Messenger = {
    init: () => {
        /*let buttons = document.getElementsByClassName('messenger-js');
        Array.from(buttons).forEach(function(button) {
            console.log(button)
            button.addEventListener('click', Messenger.open);
        });
        document.getElementById('messenger-close').addEventListener('click', Messenger.close);
        document.getElementById('form-messenger-message').addEventListener('submit', Messenger.sendMessage);*/

        //UNUSED referer to Common.js
    },
    open: () => {
        document.getElementById('messenger-js').classList.remove('d-none');
        let button = event.target;
        let notification = button.dataset.notification;
        if ('true' == notification) {
            let badge = document.querySelector('#dropdownMessengerNotifications span.badge');
            let nbNotification = parseInt(badge.textContent) - 1;
            badge.textContent = nbNotification;
        }
        Messenger.getData(button);
    },
    getData: (button) => {

        button = button.classList.contains('messenger-js') ? button :button.parentNode;
        
        var formData = new FormData();
        formData.append('from', button.dataset.from);
        formData.append('to', button.dataset.to);
        formData.append('entity', button.dataset.entity);
        formData.append('entity_id', button.dataset.entityId);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/messenger/open');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send(formData);

        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                Messenger.loadData(xhr.response);
            }
        }
    },
    close: () => {
        document.getElementById('messenger-js').classList.add('d-none');
    },
    loadData: (response) => {
        let data = JSON.parse(response);
        document.querySelector('input[name="messenger[id]"]').value = data.id;

        let messengerTitle = document.getElementById('messenger-title');
        let messengerBody = document.getElementById('messenger-body');

        let messageTemplates = '';
        Object.entries(data.messages).forEach(function(messages) {
            Array.from(messages).forEach(function(message) {
                if ("object" == typeof message) {
                    messageTemplates+= Messenger.getMessageTemplate(message);
                }
            });
        });

        messengerTitle.innerHTML = data.title;
        messengerBody.innerHTML = messageTemplates;
    },
    sendMessage: () => {
        event.preventDefault();

        let form = event.target;
        let inputId = form.querySelector('input[name="messenger[id]"]');
        let inputMessage = form.querySelector('textarea[name="messenger[messenger_message]"]');

        if (0 < inputMessage.value.length) {
            var formData = new FormData();
            formData.append('messenger[id]', inputId.value);
            formData.append('messenger[messenger_message]', inputMessage.value);

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/messenger/message/new');
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.send(formData);

            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    let message = JSON.parse(xhr.response);
                    let messageTemplate = Messenger.getMessageTemplate(message);
                    document.getElementById('messenger-body').innerHTML+= messageTemplate;
                    inputMessage.value = '';
                }
            }
        }
    },
    getMessageTemplate: (message) => {
        message.class = ''
        if (true == message.from_user) {
            message.class = 'float-right is-reverse';
        }

        return `<div class="card w-75 ${message.class}">
            <div class="card-body">
                <h5 class="card-title">${message.sender}</h5>
                <h6 class="card-subtitle mb-1">${message.send_at}</h6>
                <p class="card-text">${message.message}</p>
            </div>
        </div>
        <div class="clearfix"></div>`;
    }
};
module.exports = Messenger;
